<template>
    <ul class="SocialList">
        <li class="SocialList-Item">
            <a href="https://www.instagram.com/begrand/" target="_blank">
                <svg><use xlink:href="#icon-instagram"/></svg>
            </a>
        </li>
        <li class="SocialList-Item">
            <a href="https://www.facebook.com/begrand/" target="_blank">
                <svg><use xlink:href="#icon-facebook"/></svg>
            </a>
        </li>
    </ul>
</template>

<script>
export default {
    name: 'SocialList',
}
</script>

<style src="styles/components/SocialList.styl" lang="stylus"></style>
