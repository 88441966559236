
export default {
    install(Vue) {
        Vue.mixin({
            data() {
                return {
                    restoreScroll: false
                }
            },
            mounted() {
                if (this.restoreScroll) {
                    this.scrollToHash()
                }
            },
            methods: {
                scrollToHash(animate = false) {
                    const { hash } = this.$route;
                    if (hash) {
                        this.$nextTick(() => {
                            this.scrollTo(hash, { time: animate ? 0.7 : 0 })
                        });
                    }
                },
                scrollTo(id, behaviour = { time: 0.7 }) {
                    TweenMax.to(window, behaviour.time, {
                        scrollTo: `#${id.replace('#', '')}`,
                        ease: Cubic.easeInOut,
                    })
                }
            }
        });
    }
};
