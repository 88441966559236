import jsonata from 'jsonata'
import slugify from 'slugify'

/*
  Transforms https://dev-begrand.fooprojects.com/cms/api/pilars
  Live Editor: http://try.jsonata.org/SJugJcsTV
  Docs: https://github.com/jsonata-js/jsonata
*/
const transform = jsonata(`
\${
  $slugify(name): {
    "id": $slugify(name),
    "menu_name": name,
    "align": is_text_box_right = 1 ? "right" : "left",
    "hint": "",
    "background": {
      "mode": is_image_full_screen = 1 ? "full" : "framed",
      "image_mobile": backgroud_image_mobile,
      "image_desktop": backgroud_image_desktop
    },
    "content": {
      "title": title,
      "subtitle": sub_title,
      "description": content
    },
    "button": {
      "show": link_to != null,
      "text": "Conoce más",
      "target": is_link_to_blank ? "_blank" : "_self",
      "to": link_to
    }
  }
}
`);
transform.registerFunction('slugify', str => slugify(str, { lower: true }))

export default transform;
