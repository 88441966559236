<template>
    <div class="ResponsiveVideo">
        <video
            v-if="showVideo"
            ref="video"
            class="ResponsiveVideo-Video"
            data-object-fit="cover"
            muted muted="true" autoplay loop playsinline
            webkit-playsinline=""
            preload="metadata"
        >
            <source :src="src" type='video/mp4' >
        </video>
        <video
            v-else-if="showVideoMobile"
            ref="video"
            class="ResponsiveVideo-Video"
            data-object-fit="cover"
            muted muted="true" autoplay loop playsinline
            webkit-playsinline=""
            preload="metadata"
        >
            <source :src="srcMobile" type='video/mp4' >
        </video>
        <responsive-image
            v-if="showPoster"
            class="ResponsiveVideo-Poster"
            :mobileSrc="mobilePosterSrc"
            :desktopSrc="desktopPosterSrc"
            background
        ></responsive-image>
    </div>
</template>

<script>
import ResponsiveImage from '@/app/components/ResponsiveImage.vue'

export default {
    name: 'ResponsiveVideo',
    data() {
        return {
            playing: false,
            binded: false,
            repeatsLeft: 0,
        }
    },
    components: {
        ResponsiveImage,
    },
    props: {
        src: {
            type: String,
            default: '',
        },
        srcMobile: {
            type: String,
            default: '',
        },
        mobilePosterSrc: {
            type: String,
            default: '',
        },
        desktopPosterSrc: {
            type: String,
            default: '',
        },
        autoplay: {
            type: Boolean,
            default: false,
        },
        repeat: {
            type: Number,
            default: 0,
        }
    },
    mounted() {
        console.log("ResponsiveVideo",this.srcMobile);
        this.bind();
        // $(windows).focus(
        if(this.play) this.play();
    },
    methods: {
        bind() {
            const { video } = this.$refs
            if ((this.showVideo || this.showVideoMobile) && !this.binded) {
                video.addEventListener('playing', this.onPlay)
                video.addEventListener('pause', this.onPause)
                video.addEventListener('ended', this.onEnded)
                video.addEventListener('error', this.onError)
                this.binded = true

                video.controls    = false;
                video.playsInline = true;
                video.muted       = true;
                video.setAttribute('muted', '');
                video.autoplay    = true;

                if (this.autoplay) {
                    const promise = this.play()
                    if(promise && promise.then){
                        promise
                        .then(()=>{})
                        .catch(()=>{
                            console.log('gggggggggggg')
                        })
                    }
                }
            }
        },
        onPlay() {
            this.playing = true
            if (this.repeatsLeft > 0) {
                this.repeatsLeft--
                if (this.repeatsLeft <= 0) {
                    ///this.stop()
                }
            }
            this.$emit('play')
        },
        onPause() {
            this.playing = false
            this.$emit('pause')
        },
        onEnded() {
            this.playing = false
            this.$emit('ended')
        },
        onError() {
            console.error('An error ocurred while playing the video')
        },
        play() {
            const { video } = this.$refs
            this.repeatsLeft = this.repeat + 1
            video.loop = this.repeat > 0
            video.play()
        },
        stop() {
            const { video } = this.$refs
            if (this.playing) {
                video.pause()
                video.currentTime = 0
            }
        }
    },
    computed: {
        showVideo() {
            const { $mq } = this
            return $mq === 'desktop' && this.src
        },
        showVideoMobile() {
            const { $mq } = this
            return $mq === 'mobile' && this.srcMobile
        },
        showPoster() {
            if(this.srcMobile){
                return false;
            }
            const { playing, $mq } = this
            //return true;
            return (this.showVideoMobile && !this.showVideoMobile()) && (playing === false || $mq === 'mobile')
        },
    },
    watch: {
        showVideo: {
            handler(value) {
                if (value) {
                    this.$nextTick(this.bind)
                }
            },
            immediate: true,
        }
    }
}
</script>

<style src="styles/components/ResponsiveVideo.styl" lang="stylus"></style>
