import jsonata from 'jsonata'

/*
  Transforms https://dev-begrand.fooprojects.com/cms/api/page/by_perma_link
  Live Editor: http://try.jsonata.org/S1Hr1qopN
  Docs: https://github.com/jsonata-js/jsonata
*/
const transform = jsonata(`
{
  "id": "section-home-carousel",
  "menu_name": "Home",
  "meta_tags": {
    "title": meta_title,
    "description": meta_description
  },
  "slides": [
    {
      "background": {
        "image_mobile": backgroud_image_mobile,
        "image_desktop": backgroud_image_desktop,
        "video": video_desktop,
        "video_mobile": video_mobile
      },
      "content": {
        "title": "",
        "title_image": title_image,
        "subtitle": sub_title,
        "description": description,
        "name": name
      }
    },
    gallery.{
      "campaign_id": campaign_id,
      "background": {
        "image_mobile": image_mobile,
        "image_desktop": image_desktop,
        "video": video_desktop,
        "video_mobile": video_mobile,
        "path": url
      }
    }
  ]
}
`);

export default transform;
