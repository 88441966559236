import { mapState } from "vuex";

export default {
    computed: {
        highlightModifiers() {
            const { complex } = this
            const complexOff = complex && !complex.highlight
            const complexOn = complex && complex.highlight
            return {
                "Content--highlight-off": complexOff,
                "Content--highlight-on": complexOn
            };
        },
        complex() {
            const { $route, contents } = this
            if ($route.params.complex && Array.isArray(contents.complexes)) {
                const re = new RegExp($route.params.complex)
                return contents.complexes.find(complex => complex.permalink.match(re))
            }
            return null
        },
        ...mapState({
            contents: state => state.app.contents,
            highlight: state => state.app.layout.highlight_content
        })
    }
};
