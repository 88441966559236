<template>
    <section id="foundation">
        <div id="foundation-description">
            <h1><flat-button
                el='router-link'
                iconId="icon-arrow-left"
                iconLeft
                to= '/' outline>Volver</flat-button>
            </flat-button><span>{{ page.content.title }}</span>{{ page.content.subtitle }} <img :src="page.images.logo.image_desktop" alt="Fundación BeGrand"></h1>
            <p v-html="page.content.description"></p>
            <flat-button class="Foundation-ViewButton item-transition" :href="page.button.href" el="a" target="_blank" outline>Ver Más</flat-button>
        </div>
        <div id="foundation-slider-container">
            <div id="pagination-container">
                <span class='indicator'>01</span>
                <div class="swiper-pagination" slot="pagination"></div>
                <span id="total-items" class='indicator'>06</span>
            </div>
            <swiper :options="swiperOption" ref="mySwiper" @transitionEnd="pauseVideos">
                <li v-for="slide in gallery" class="swiper-slide">
                    <div v-if="slide.video_desktop" class="video-container" @click="playVideo">
                        <svg class="play-video" width="163.861px" height="163.861px" viewBox="0 0 163.861 163.861" style="enable-background:new 0 0 163.861 163.861;" xml:space="preserve"><path d="M34.857,3.613C20.084-4.861,8.107,2.081,8.107,19.106v125.637c0,17.042,11.977,23.975,26.75,15.509L144.67,97.275 c14.778-8.477,14.778-22.211,0-30.686L34.857,3.613z"/></svg>
                        <video class='video-desktop' playsinline :src="slide.video_desktop"></video>
                        <video class='video-mobile' playsinline :src="slide.video_mobile"></video>
                        <img :src="slide.image_desktop" :alt="page.content.title">
                    </div>
                    <img v-else :src="slide.image_desktop" :alt="page.content.title">
                </li>
                <div class="CarouselNavigator-PrevButton" slot="button-prev">
                    <div class="CarouselNavigator-Background"></div>
                    <div class="CarouselNavigator-ArrowLeft">
                        <svg><use xlink:href="#icon-arrow-tail-left"/></svg>
                    </div>
                </div>
                <div class="CarouselNavigator-NextButton" slot="button-next">
                    <div class="CarouselNavigator-Background"></div>
                    <div class="CarouselNavigator-ArrowRight">
                        <svg><use xlink:href="#icon-arrow-tail-right"/></svg>
                    </div>
                </div>
            </swiper>
        </div>
        <view-nav :items="sections" :item-active="activeSection" light />
    </section>
</template>
<script>
import { swiper } from 'vue-awesome-swiper'
import inView from "in-view"
import { LAYOUT_SOCIAL_THEME } from '@/app/store/modules/app'
import ViewNav from '@/app/components/navs/ViewNav.vue'
import MixHomeSections from '@/app/mixins/MixHomeSections'
import MixHead from '@/app/mixins/MixHead'
import { environment } from '../../config/index'
import axios from "axios"
import ResponsiveImage from '@/app/components/ResponsiveImage.vue'
import SectionUtils from '@/app/mixins/SectionUtils'
import FlatButton from '@/app/components/buttons/FlatButton.vue'
import SectionPage from '@/app/mixins/SectionPage'
import isMobile from 'ismobilejs'

export default {
    name: 'Foundation',
    mixins: [
        MixHead,
        MixHomeSections,
        SectionUtils,
        SectionPage,
    ],
    data() {
        return {
            swiperOption: {
                loop: true,
                notNextTick: true,
                pagination: {
                    el: '.swiper-pagination',
                    type: 'progressbar',
                },
                navigation: {
                    nextEl: '.CarouselNavigator-NextButton',
                    prevEl: '.CarouselNavigator-PrevButton',
                },
                onSlideChangeEnd: () => {
                    this.pauseVideos();
                }
            },
            gallery: [],
            activeSection: 'foundation',
            viewport: {
                height: 100,
                predicate: (w, h) => w > 860 && h > 700,
                onResize: () => {
                    this.onResize()
                },
            },
        }
    },
    components: {
        swiper,
        ViewNav,
        ResponsiveImage,
        FlatButton,
    },
    methods: {
        playVideo(e) {
            this.pauseVideos();
            e.target.classList.add("in-play");
            if (this.mobile) {
                e.target.querySelector(".video-mobile").play();
            } else {
                e.target.querySelector(".video-desktop").play();
            }
        },
        pauseVideos() {
            let containers = document.getElementsByClassName("video-container");
            for (var i = 0; i < containers.length; i++) {
                containers[i].classList.remove("in-play");
            }
            let videos = document.getElementsByTagName("video");
            for (var i = 0; i < videos.length; i++) {
                videos[i].pause();
            }
        }
    },
    mounted() {
        this.mobile = isMobile.phone;
        axios.get(`${environment.url.api}/page/by_perma_link?perma_link=/fundacion`)
            .then((data) => {
                this.gallery = data.data.response.entry.gallery;
                let str = String(this.gallery.length);
                window["total-items"].innerHTML = str.length == 1 ? "0" + str : str;
            });
        inView('#foundation .item-transition').on('enter', (el) => {
            el.classList.add("inview");
        })
        this.$store.dispatch(LAYOUT_SOCIAL_THEME, 'light');
        this.readyToRender();
    },    
    computed: {
        metas() {
            const { page } = this
            return page.meta_tags
        }
    }
}
</script>
<style src="styles/components/carousel/CarouselNavigator.styl" lang="stylus"></style>
<style src="styles/components/carousel/Carousel.styl" lang="stylus"></style>
<style src="styles/views/Foundation.styl" lang="stylus"></style>
