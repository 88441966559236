import VueRouter from "vue-router";
import PreloaderCanvas from "@/app/utils/preloader-canvas"
import store from '@/app/store'
import * as StoreConst from '@/app/store/modules/app'
import Home from '@/app/views/Home.vue'
import About from '@/app/views/About.vue'
import Sustainability from '@/app/views/Sustainability.vue'
import Foundation from '@/app/views/Foundation.vue'
import Complex from '@/app/views/Complex.vue'
import PreviousWorks from '@/app/views/PreviousWorks.vue'
import Complexes from '@/app/views/Complexes.vue'
import ContentsPage from '@/app/views/ContentsPage.vue'
import JobsPage from '@/app/views/JobsPage.vue'
import InvestorsPage from '@/app/views/InvestorsPage.vue'
import ComplexGallery from '@/app/views/ComplexGallery.vue'
import ComplexSchedule from '@/app/views/ComplexSchedule.vue'
import ApartmentType from '@/app/views/ApartmentType.vue'
import ScheduleThankYou from '@/app/views/ScheduleThankYou.vue'
import NotFound from '@/app/views/NotFound.vue'

import { environment } from '../config/index'
// import Preloader from "@/app/utils/preloaderG"

const routes = {
    mode: 'history',
    base: environment.url.subdirectory,
    routes: [{
        path: '/',
        name: 'home',
        component: Home,
    }, {
        path: '/departamentos-en-venta',
        name: 'complexes',
        component: Complexes,
    }, {
        path: '/departamentos-en-venta/:complex',
        name: 'complex',
        component: Complex,
    }, {
        path: '/departamentos-en-venta/:complex/galeria',
        name: 'complex-gallery',
        component: ComplexGallery,
    }, {
        path: '/departamentos-en-venta/:complex/tipo/:apartment/id/:id',
        name: 'apartment-type',
        component: ApartmentType,
    }, {
        path: '/departamentos-en-venta/:complex/contacto',
        name: 'schedule',
        component: ComplexSchedule,
    }, {
        // Aca debe cambiar <cita> por <contacto>
        path: '/departamentos-en-venta/gracias/:complex/cita',
        name: 'schedule-thank-you',
        component: ScheduleThankYou,
    }, {
        path: '/desarrollos-anteriores',
        name: 'previous-works',
        component: PreviousWorks,
    }, {
        path: '/gobierno-corporativo',
        name: 'corporate-governance',
        component: ContentsPage,
    }, {
        path: '/responsabilidad-corporativa',
        name: 'corporate-responsibility',
        component: ContentsPage,
    }, {
        path: '/politica-de-cancelacion',
        name: 'cancellation-policy',
        component: ContentsPage,
    }, {
        path: '/bolsa-de-trabajo',
        name: 'jobs',
        component: JobsPage,
    }, {
        path: '/acerca-de',
        name: 'about-alt',
        component: ContentsPage,
    }, {
        path: '/quienes-somos',
        name: 'about',
        component: About,
    }, {
        path: '/inversionistas',
        name: 'investors',
        component: InvestorsPage,
    }, {
        path: '/sustentabilidad',
        name: 'sustainability',
        component: Sustainability,
    }, {
        path: '/fundacion',
        name: 'foundation',
        component: Foundation,
    }, {
        path: '*',
        name: '404',
        component: NotFound,
    }],
    scrollBehavior(to, from, savedPosition) {
        if (to.hash) {
            return false
        }
        return savedPosition || {
            x: 0,
            y: 0
        }
    },
}

const router = new VueRouter(routes);
const preloader = new PreloaderCanvas("preloader-canvas");

async function onBeforeEach(to, from, next) {
    if (preloader.loaded) preloader.show();
    store.watch(
        (_, getters) => getters.contentLoaded,
        (loaded) => {
            if (loaded) next();
        }
    )
    store.dispatch(StoreConst.PREV_ROUTE, from)
    store.dispatch(StoreConst.LAYOUT_HEADER_LOGO, '')
    store.dispatch(StoreConst.API_FETCH_COMPLEXES)
    store.dispatch(StoreConst.API_FETCH_SECTIONS)
    store.dispatch(StoreConst.API_FETCH_PAGES)
    store.dispatch(StoreConst.API_FETCH_HOME)
    store.dispatch(StoreConst.API_FETCH_PREVIOUS_WORKS)
    if (store.getters.contentLoaded) {
        return setTimeout(() => next(), 500);
    }
    return false;
}

function onAfterEach() {
    setTimeout(() => {
        window.setChat();
    }, 500);
}

router.beforeEach(onBeforeEach)
router.afterEach(onAfterEach)

export default router;
