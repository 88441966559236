<template>
    <div class="SectionAboutWorks">
        <h1 class="item-transition">{{ prevWorks.content.title }}</h1>
        <h2 class="item-transition">{{ currentTag }}</h2>
        <ul class="SectionAboutWorks-TagList item-transition">
            <li
                class="SectionAboutWorks-TagItem"
                :class="getTagItemModifiers(tag)"
                v-for="(tag, index) in prevWorks.tags"
                :key="index"
                @click="currentTag = tag"
            >
                {{ tag }}
            </li>
        </ul>
        <complexes-grid
            :items='filteredItems'
        />
    </div>
</template>

<script>
import { mapState } from 'vuex'
import ComplexesGrid from '@/app/components/ComplexesGrid.vue'

export default {
    name: 'SectionAboutWorks',
    components: {
        ComplexesGrid,
    },
    data() {
        return {
            currentTag: null,
        }
    },
    mounted() {
        this.selectFirstTag()
    },
    methods: {
        selectFirstTag() {
            const { prevWorks } = this
            // eslint-disable-next-line
            this.currentTag = prevWorks.tags[0]
        },
        getTagItemModifiers(item) {
            const { currentTag } = this
            return {
                'WrapperGrid-TagItem--active': item === currentTag
            }
        },
    },
    computed: {
        ...mapState({
            prevWorks: state => state.app.contents.prev_works,
        }),
        filteredItems() {
            const { prevWorks, currentTag } = this
            if (currentTag) {
                return prevWorks.items
                    .filter(item => item.tag === currentTag)
            }
            return prevWorks.items
        },
        showTagsFilter() {
            const { $mq, prevWorks } = this
            const { tags } = prevWorks
            const isDesktop = $mq === 'desktop'
            const hasTags = Array.isArray(tags) && tags.length > 0
            return isDesktop && hasTags
        }
    },
}
</script>

<style src="styles/components/sections/SectionAboutWorks.styl" lang="stylus"></style>
