import jsonata from 'jsonata'
import slugify from 'slugify'

/*
  Transforms https://dev-begrand.fooprojects.com/cms/api/complexes
  Live Editor:
  Docs: https://github.com/jsonata-js/jsonata
*/
const transform = jsonata(`$.{
  "name": name,
  "colorname": colorname,
  "permalink": perma_link,
  "header_logo": logo_image,
  "highlight": highlight = 1,
  "meta_tags": {
    "title": meta_title,
    "description": meta_description
  },
  "archive_entry": {
    "title": name,
    "tag": "Be Grand",
    "description": menu_description,
    "thumb": {
      "image_desktop": menu_image_desktop,
      "image_mobile": menu_image_mobile
    },
    "path": perma_link,
    "external_url": external_url
  },
  "hero": {
    "id": "inicio",
    "menu_name": name,
    "slides": [
      {
        "background": {
          "image_desktop": backgroud_image_desktop,
          "image_mobile": backgroud_image_mobile,
          "video": video_desktop,
          "video_mobile": video_mobile
        },
        "content": {
          "title": name,
          "colorname": colorname,
          "subtitle": "Departamentos",
          "description": description
        }
      },
      sliders.{
        "campaign_id": campaign_id,
        "background": {
          "image_desktop": image_desktop,
          "image_mobile": image_mobile,
          "video": video_desktop,
          "video_mobile": video_mobile,
          "path": url
        }
      }
    ]
  },
  "apartments": {
    "id": "tipos",
    "hint": "Apartment Hint",
    "menu_name": "Departamentos",
    "show_gallery": $count(apartments.sliders) > 0,
    "background": {
      "image_mobile": apartment_image_mobile,
      "image_desktop": apartment_image_desktop
    },
    "content": {
      "title": "Tipos",
      "subtitle": apartment_title,
      "description": apartment_description_principal
    },
    "slides": [
      slider_apartments.{
        "background": {
          "image_desktop": image_desktop,
          "image_mobile": image_mobile
        }
      }
    ],
    "layouts": apartments.{
      "id": $slugify(name),
      "idapa": id
    },
    "videos": [
      videos.{
        "src": video_desktop
      }
    ],
    "tours": [tours.{
      "text": name,
      "iframe_url": url
    }],
    "threeSixty": [
      apartments[apartment_360 != null].{
        "text": name,
        "iframe_url": apartment_360
      }
    ]
  },
  "amenities": {
    "id": "amenidades",
    "menu_name": "Amenidades",
    "slides": amenities.{
      "content": {
        "title": "Amenidades",
        "subtitle": name
      },
      "background": {
        "image_mobile": backgroud_image_mobile,
        "image_desktop": backgroud_image_desktop
      }
    }
  },
  "map": {
    "id": "ubicacion",
    "menu_name": "Ubicación",
    "lat": location_latitud,
    "lng": location_longitud,
    "content": {
      "title": "Ubicación",
      "name": name,
      "phone": location_phone,
      "address": location_address,
      "map_url": location_url
    }
  },
  "apartments_gallery": {
    "items": [ apartments.sliders.{
        "background": {
          "image_desktop": image_desktop,
          "image_mobile": image_mobile
        }
      }
    ]
  },
  "apartment_types": apartments.{
    "id": $slugify(name),
    "idapa": id,
    "content": {
      "title": name,
      "price_text": "$" & specs_price,
      "price_enabled": specs_price != null
    },
    "background": {
      "image_desktop": backgroud_image_desktop,
      "image_mobile": backgroud_image_mobile
    },
    "layouts": sketchs[].{
      "image_desktop": image_desktop,
      "image_mobile": image_desktop
    },
    "features": [
      specs_m2_total != null ? {
        "icon": "total-size",
        "text": "<b>Medida Total:</b> " & specs_m2_total
      },
      specs_m2_inside != null ? {
        "icon": "interior-size",
        "text": "<b>Medidas Interior:</b> " & specs_m2_inside
      },
      specs_m2_terrace != null ? {
        "icon": "roof-size",
        "text": "<b>Medidas en Terraza:</b> " & specs_m2_terrace
      },
      specs_roof_garden != null ? {
        "icon": "roof-garden",
        "text": "<b>Roof Garden:</b> " & specs_roof_garden
      },
      specs_bedrooms != null ? {
        "icon": "rooms",
        "text": "<b>Recamaras:</b> " & specs_bedrooms
      },
      specs_bathrooms != null ? {
        "icon": "bathroom",
        "text": "<b>Baños:</b> " & specs_bathrooms
      },
      specs_servicerooms != null ? {
        "icon": "utility-room",
        "text": "<b>Cuarto de Servicio:</b> " & specs_servicerooms
      },
      specs_parking != null ? {
        "icon": "parking",
        "text": "<b>No. de Estacionamientos:</b> " & specs_parking
      }
    ]
  },
  "schedule": {
    "id": "contacto",
    "menu_name": "Contacto",
    "background": {
      "image_desktop": form_image_desktop,
      "image_mobile": form_image_mobile
    },
    "content": {
      "title": "Contacto"
    }
  }
}`);

transform.registerFunction('slugify', str => slugify(str, { lower: true }))

export default transform;
