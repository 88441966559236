<template>
    <section
        :id="id"
        class="SectionSchedule"
        v-viewport-size:debounce.100='viewport'
    >
        <responsive-image
            class="SectionSchedule-Image item-transition"
            :mobileSrc="data.background.image_mobile"
            :desktopSrc="data.background.image_desktop"
            background
        >
        </responsive-image>
        <schedule-widget :items="complexesItems" ></schedule-widget>
    </section>
</template>

<script>
import { mapState } from 'vuex'
import inView from "in-view"
import ScheduleWidget from '@/app/components/ScheduleWidget.vue'
import ResponsiveImage from '@/app/components/ResponsiveImage.vue'

export default {
    name: 'SectionSchedule',
    data() {
        return {
            viewport: {
                height: 100,
                predicate: (w, h) => w > 850 && h > 800,
            },
        }
    },
    mounted() {
        inView('.SectionSchedule-Image').on('enter', (el) => {
            el.classList.add("inview");
        });
    },
    props: {
        id: {
            type: String,
            required: true,
        },
        data: {
            type: Object,
            default: () => ({
                background: {},
                content: {}
            }),
        },
    },
    components: {
        // FlatButton,
        ScheduleWidget,
        ResponsiveImage,
    },
    computed: {
        ...mapState({
            complexesItems: state => state.app.data.complexes.items,
        }),
    }


}
</script>

<style src="styles/components/sections/SectionSchedule.styl" lang="stylus"></style>
