<template>
    <div class="FormSelect" :class="modifiers">
        <label class="FormSelect-label" :for="id">
            {{ label }}
            <select
                ref="select"
                :id="id"
                :name="id"
                :placeholder="placeholder"
                v-model="selected"
                class="FormSelect-Select"
            >
                <option
                    v-for="(item, index) in options"
                    :key="index"
                    :value="item.value"
                >
                    {{ item.text }}
                </option>
            </select>
            <svg class="FormSelect-Icon">
                <use xlink:href="#icon-arrow-down"/>
            </svg>
        </label>
    </div>
</template>

<script>
import { uniqueId } from 'lodash'

export default {
    name: 'FormSelect',
    data() {
        return {
            selected: null,
        }
    },
    props: {
        value: String,
        error: String,
        id: {
            type: String,
            default: () => uniqueId('form-select-'),
        },
        label: {
            type: String,
            required: true,
        },
        placeholder: {
            type: String,
            default: '',
        },
        disabled: {
            type: Boolean,
            default: false,
        },
        options: {
            type: Array,
            default: () => [],
        },
    },
    methods: {
        selectFirst() {
            const { options } = this
            if (options.length > 0) {
                this.selected = options[0].value
            }
        },
        selectByValue(value) {
            const { options } = this
            const option = options.find(op => op.value === value)
            if (option) {
                this.selected = option.value
            }
        }
    },
    computed: {
        modifiers() {
            const { disabled, error } = this
            return {
                'FormSelect--disabled': disabled,
                'FormSelect--error': !!error,
            }
        },
    },
    watch: {
        options: {
            handler() {
                this.selectFirst()
            },
            immediate: true,
        }
    }
}
</script>

<style src="styles/components/form/FormSelect.styl" lang="stylus"></style>
