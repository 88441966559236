<template>
    <section class="ContentsPage" id="investors-section">
        <header>
            <section class="WrapperInfo">
                <drag-hint hide-on-mobile light />
                <responsive-image
                    class="WrapperInfo-Cover"
                    :mobileSrc="top_img_mobile"
                    :desktopSrc="top_img_desktop"
                    background
                />
                <div class="WrapperInfo-Wrapper">
                    <flat-button
                        :to="{ name: 'about' }"
                        el="router-link"
                        class="WrapperInfo-BackButton"
                        outline
                    >
                        Volver
                    </flat-button>
                    <h2 class="WrapperInfo-SectionName">
                        <slot name="sectionName">Acerca de</slot>
                    </h2>
                    <h1 class="WrapperInfo-Title">
                        <slot name="title">{{ title }}</slot>
                    </h1>
                    <div class="WrapperInfo-btn-dl">
                        <div
                            class="wrapperInfo-btn-dl-txt"
                            style="text-align:center"
                        >
                            <p>Consulta nuestra declaratoria ambiental</p>
                        </div>
                        <a
                            href="https://begrand.mx/BG_050224_129%20AP%20declaratoria%20ASG%20BG_4SEP.pdf"
                            download
                            class="pdf-btn"
                        >
                            <button>
                                DESCARGAR
                                <div class="btn-icon">
                                    <svg
                                        xmlns="http://www.w3.org/2000/svg"
                                        width="100%"
                                        height="100%"
                                        viewBox="0 0 141.162 151.347"
                                    >
                                        <g
                                            id="download-btn-icon"
                                            transform="translate(-722 -464.326)"
                                        >
                                            <path
                                                class="download-btn-icon"
                                                d="M82.887,54.39V4.85A4.851,4.851,0,0,0,78.037,0H63.692A4.851,4.851,0,0,0,58.841,4.85V54.39a5.346,5.346,0,0,1-5.346,5.346H34.39A3.723,3.723,0,0,0,31.6,65.921l34.912,39.6a5.8,5.8,0,0,0,8.708,0l34.912-39.6a3.723,3.723,0,0,0-2.792-6.185h-19.1a5.346,5.346,0,0,1-5.346-5.346"
                                                transform="translate(722 464.327)"
                                            />
                                            <path
                                                class="download-btn-icon"
                                                d="M119.1,112.1v17.04H22.066V112.1a5.167,5.167,0,0,0-5.167-5.167H5.167A5.167,5.167,0,0,0,0,112.1v28.919a10.328,10.328,0,0,0,10.328,10.328H130.834a10.328,10.328,0,0,0,10.328-10.328V112.1A5.166,5.166,0,0,0,136,106.932H124.264A5.167,5.167,0,0,0,119.1,112.1"
                                                transform="translate(722 464.327)"
                                            />
                                        </g>
                                    </svg>
                                </div>
                            </button>
                        </a>
                    </div>
                </div>
            </section>
        </header>
        <div
            class="investors_page"
            style="border: 1px solid rgb(222, 222, 222);width:100%;display:block;margin:5% auto; padding:0;max-width:1160px;"
        >
            <div>
                <h4
                    style="font-family:HeadingCompressedPro,sans-serif;margin-top:1em; font-size: 2em; text-decoration:underline"
                >
                    Relación con Inversionistas
                </h4>
                <p>Abril Suarez Vazquez</p>
                <p>
                    Correo:
                    <a href="mailto:inversionistas@begrand.mx"
                        >inversionistas@begrand.mx</a
                    >
                </p>
                <p>Tel: +52 (55) 1100-1110</p>
            </div>
        </div>
        <h3 class="table-title" v-on:click="showTable">
            Últimos reportes <span class="icon">></span>
        </h3>
        <div class="table-wrapper active">
            <table>
                <tbody>
                    <tr v-for="(_new, index) in news" v-bind:key="index">
                        <td class="over">
                            <a
                                :href="_new.file"
                                class="download-file"
                                :title="_new.name"
                                download
                                target="_blank"
                            >
                                <span>{{ _new.name }} - {{ _new.year }}</span>
                            </a>
                        </td>
                    </tr>
                </tbody>
            </table>
        </div>
        <div
            class="table-container"
            v-for="(group, index) in filter(groups, 0, 3)"
            v-bind:key="index"
        >
            <h3 class="table-title" v-on:click="showTable">
                {{ group[0].category_file_pages[0].name }}
                <span class="icon">></span>
            </h3>
            <div class="table-wrapper" :id="index">
                <table>
                    <tbody>
                        <tr
                            v-for="(files, index) in sort_by(group, 'year')
                                .slice()
                                .reverse()"
                            v-bind:key="index"
                        >
                            <td>{{ files[0].year }}</td>
                            <td
                                v-for="(f, index) in sort_by(files, 'group')"
                                v-bind:class="[f.length == 1 ? 'over' : '']"
                                v-bind:key="index"
                            >
                                <table v-if="f.length > 1">
                                    <tbody>
                                        <tr
                                            v-for="(file, index) in f"
                                            v-bind:key="index"
                                        >
                                            <td class="over">
                                                <a
                                                    :href="file.file"
                                                    class="download-file"
                                                    :title="file.name"
                                                    target="_blank"
                                                    download
                                                    ><span>{{
                                                        file.name
                                                    }}</span></a
                                                >
                                            </td>
                                        </tr>
                                    </tbody>
                                </table>
                                <a
                                    v-else
                                    :href="f[0].file"
                                    class="download-file"
                                    :title="f[0].name"
                                    target="_blank"
                                    download
                                    ><span>{{ f[0].name }}</span></a
                                >
                            </td>
                        </tr>
                    </tbody>
                </table>
            </div>
        </div>
        <div class="table-container" id="half-tables-container">
            <table
                v-for="(group, index) in filter(groups, 5, 6)"
                v-bind:key="index"
            >
                <thead>
                    <th>{{ group[0].category_file_pages[0].name }}</th>
                </thead>
                <tbody>
                    <tr
                        v-for="(files, index) in sort_by(group, 'year')
                            .slice()
                            .reverse()"
                        v-bind:key="index"
                    >
                        <td
                            v-for="(f, index) in sort_by(files, 'group')"
                            v-bind:class="[f.length == 1 ? 'over' : '']"
                            v-bind:key="index"
                        >
                            <table v-if="f.length > 1">
                                <tbody>
                                    <tr
                                        v-for="(file, index) in f"
                                        v-bind:key="index"
                                    >
                                        <td class="over">
                                            <a
                                                :href="file.file"
                                                class="download-file"
                                                :title="file.name"
                                                target="_blank"
                                                download
                                                ><span>{{ file.name }}</span></a
                                            >
                                        </td>
                                    </tr>
                                </tbody>
                            </table>
                            <a
                                v-else
                                :href="f[0].file"
                                class="download-file"
                                :title="f[0].name"
                                target="_blank"
                                download
                                ><span>{{ f[0].name }}</span></a
                            >
                        </td>
                    </tr>
                </tbody>
            </table>
        </div>
        <div
            class="table-container"
            v-for="(group, index) in filter(groups, 4, 4)"
            v-bind:key="index"
        >
            <h3 class="table-title" v-on:click="showTable">
                {{ group[0].category_file_pages[0].name }}
                <span class="icon">></span>
            </h3>
            <div class="table-wrapper" :id="index">
                <table>
                    <tbody>
                        <tr
                            v-for="(files, index) in sort_by(group, 'year')
                                .slice()
                                .reverse()"
                            v-bind:key="index"
                        >
                            <td>{{ files[0].year }}</td>
                            <td
                                v-for="(f, index) in sort_by(files, 'group')"
                                v-bind:class="[f.length == 1 ? 'over' : '']"
                                v-bind:key="index"
                            >
                                <table v-if="f.length > 1">
                                    <tbody>
                                        <tr
                                            v-for="(file, index) in f"
                                            v-bind:key="index"
                                        >
                                            <td class="over">
                                                <a
                                                    :href="file.file"
                                                    class="download-file"
                                                    :title="file.name"
                                                    target="_blank"
                                                    download
                                                    ><span>{{
                                                        file.name
                                                    }}</span></a
                                                >
                                            </td>
                                        </tr>
                                    </tbody>
                                </table>
                                <a
                                    v-else
                                    :href="f[0].file"
                                    class="download-file"
                                    :title="f[0].name"
                                    target="_blank"
                                    download
                                    ><span>{{ f[0].name }}</span></a
                                >
                            </td>
                        </tr>
                    </tbody>
                </table>
            </div>
        </div>
    </section>
</template>
<script>
import ResponsiveImage from "@/app/components/ResponsiveImage.vue";
import FlatButton from "@/app/components/buttons/FlatButton.vue";
import DragHint from "@/app/components/DragHint.vue";
import SectionPage from "@/app/mixins/SectionPage";
import MixHead from "@/app/mixins/MixHead";
import axios from "axios";
import TweenMax from "gsap";
import { groupBy, orderBy } from "lodash";
import { environment } from "../../config/index";

export default {
    name: "ContentsPage",
    components: {
        ResponsiveImage,
        DragHint,
        FlatButton
    },
    mixins: [SectionPage, MixHead],
    data() {
        return {
            title: "",
            data: null,
            news: [],
            top_img_desktop: "",
            top_img_mobile: "",
            groups: []
        };
    },
    mounted() {
        axios
            .get(
                `${environment.url.api}/page/by_perma_link?perma_link=/inversionistas`
            )
            .then((data) => {
                this.data = data.data.response.entry;
                this.title = this.data.title;
                this.top_img_desktop = data.data.response.entry.top_image_desktop;
                this.top_img_mobile = data.data.response.entry.top_image_mobile;
                this.news = this.data.file_news.slice(0, 2);
                this.groups = groupBy(this.data.file, "category_file_pages_id");
            });
    },
    methods: {
        showTable(e) {
            const container = e.target.nextSibling;
            const table = container.children[0];
            if (e.target.getAttribute("class").indexOf("active") < 0) {
                setTimeout(() => {
                    this.showDocs(container, table, e.target);
                }, 50);
            }
            TweenMax.set(".table-title.active", {
                className: "-=active"
            });
            TweenMax.to(".table-wrapper.active", 0.3, {
                ease: Power3.easeOut,
                className: "-=active",
                height: 0
            });
        },
        showDocs(container, table, target) {
            TweenMax.set(target, {
                className: "+=active"
            });
            TweenMax.to(container, 0.3, {
                ease: Power3.easeOut,
                className: "+=active",
                height: table.getBoundingClientRect().height + 2
            });
        },
        filter(data, a, b) {
            const group = [];
            Object.keys(data).forEach((element) => {
                if (
                    data[element][0].category_file_pages_id >= a
                  && data[element][0].category_file_pages_id <= b
                ) {
                  group.push(data[element]);
                }
            });
            return group;
        },
        sort_by(array, attr) {
            const grouped = groupBy(array, attr);
            return orderBy(grouped, ["year"]);
        }
    },
    computed: {
        /*
        metas() {
            const { section } = this
            return section.meta_tags
        }
        */
    }
};
</script>
<style src="styles/views/ContentsPage.styl" lang="stylus"></style>
