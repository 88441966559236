import jsonata from 'jsonata'

/*
  Transforms https://dev-begrand.fooprojects.com/cms/api/pages
  Docs: https://github.com/jsonata-js/jsonata
*/
const transform = jsonata(`$.{
  "permalink": perma_link,
  "meta_tags": {
    "title": meta_title,
    "description": meta_description
  },
  "popup":{
    "img" : popup_img,
    "title" : popup_title,
    "description" : popup_description
  },
  "background": {
    "image_mobile": backgroud_image_mobile,
    "image_desktop": backgroud_image_desktop,
    "video": video_desktop,
    "video_mobile": video_mobile
  },
  "images": {
    "top": {
      "image_desktop": top_image_desktop,
      "image_mobile": top_image_mobile
    },
    "bottom": {
      "image_desktop": bottom_image_desktop,
      "image_mobile": bottom_image_mobile
    },
    "logo": {
      "image_desktop": pin_image
    }
  },
  "button": {
    "href": see_more
  },
  "blocks": [
    "content".{
      "subtitle": subtitle,
      "image_top": image_top,
      "image_bottom": image_bottom,
      "description": description
    }
  ],
  "content": {
    "title": title,
    "title_image": title_image,
    "subtitle": sub_title,
    "description": description
  },
  "slides": [
    gallery.{
      "background": {
        "image_desktop": image_desktop,
        "image_mobile": image_mobile,
        "video_desktop": video_desktop,
        "video_mobile": video_mobile,
        "path": url
      }
    }
  ]
}`);

export default transform;
