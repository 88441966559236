<template>
    <swiper
        :id="id"
        v-if="optionsSet"
        ref="swiperElement"
        class="Carousel"
        :options="sliderOptions"
    >
        <slot></slot>
    </swiper>
</template>

<script>
import { swiper } from 'vue-awesome-swiper'

export default {
    name: 'Carousel',
    components: {
        swiper,
    },
    data() {
        return {
            swiper: null,
            optionsSet: false,
            index: 0,
            count: 0,
            progress: 0,
        }
    },
    props: {
        options: {
            type: Object,
            default: () => ({}),
        }
    },
    mounted() {
        this.rebindSwiper()
        this.bindEvents()
    },
    destroyed() {
        this.unbindEvents()
    },
    updated() {
        this.updateCounters()
    },
    methods: {
        nextSlide() {
            if (this.swiper) {
                this.swiper.slideNext()
            }
        },
        prevSlide() {
            if (this.swiper) {
                this.swiper.slidePrev()
            }
        },
        slideTo(index) {
            if (this.swiper) {
                if (this.swiper.params.loop) {
                    this.swiper.slideToLoop(index)
                } else {
                    this.swiper.slideTo(index)
                }
            }
        },
        onInit() {
            this.$nextTick(() => {
                const { swiperElement } = this.$refs
                this.swiper = swiperElement.swiper
                this.updateCounters()
                this.$emit('initialized')
                /* fix wrong slides position until resize event */
                this.swiper.update()
            })
        },
        onSlideClick(e) {
            const slideElement = e.target.closest(`#${this.id} .CarouselSlide`)
            if (slideElement) {
                const index = Number(slideElement.dataset.swiperSlideIndex) || this.index
                this.$emit('slideClick', index)
            }
        },
        bindEvents() {
            document.addEventListener('click', this.onSlideClick)
        },
        unbindEvents() {
            document.removeEventListener('click', this.onSlideClick)
        },
        onProgress() {
            if (this.swiper) {
                console.log(this.swiper.slides)
            }
        },
        onSlideChange() {
            if (this.swiper) {
                const { previousIndex, realIndex } = this.swiper
                this.updateCounters()
                this.$emit('change', previousIndex, realIndex)
            }
        },
        onSlideNext() {
            this.$emit('slideNext')
        },
        onSlidePrev() {
            this.$emit('slidePrev')
        },
        onSlideChangeEnd() {
            if (this.swiper) {
                const { previousIndex } = this.swiper
                this.$emit('afterLeave', previousIndex)
            }
        },
        onSlideChangeStart() {
            if (this.swiper) {
                const { realIndex } = this.swiper
                this.$emit('beforeEnter', realIndex)
            }
        },
        updateCounters() {
            const { swiper } = this
            if (swiper) {
                const loopSlides = swiper.params.loop ? 2 : 0
                this.index = swiper.realIndex
                this.count = swiper.slides.length - loopSlides
                this.progress = swiper.progress
            }
        },
        rebindSwiper() {
            this.optionsSet = false
            this.swiper = null
            this.$nextTick(() => {
                this.optionsSet = true
            })
        },
    },
    computed: {
        sliderOptions() {
            const defaultOptions = {
                // loop: true,
                speed: 250,
                grabCursor: false,
                watchSlidesProgress: true,
                mousewheelControl: true,
                keyboardControl: true,
                /*
                    virtualTranslate: bool
                    Enabled this option and swiper will be operated as usual except it will not move,
                    real translate values on wrapper will not be set. Useful when you may need to
                    create custom slide transition
                */
                virtualTranslate: false,
                // allowTouchMove: false,
                on: {
                    //progress: this.onProgress,
                    init: this.onInit,
                    slideChange: this.onSlideChange,
                    /*
                        Unfortunately we can't use the events from swiper because
                        when attaching the event listener no arguments are received.
                        Although docs states otherwise. See (click) event:
                        https://idangero.us/swiper/api/#events
                        [!] You could attach @click event outside on each slide but then
                        you wont have events on cloned slides created automatically by the swiper
                        when you set option loop to true.
                    */
                    //click: this.onSlideClick,
                    slideNextTransitionStart: this.onSlideNext,
                    slidePrevTransitionStart: this.onSlidePrev,
                    slideChangeTransitionEnd: this.onSlideChangeEnd,
                    slideChangeTransitionStart: this.onSlideChangeStart,
                }
            }
            return { ...defaultOptions, ...this.options }
        },
        id() {
            // eslint-disable-next-line
            return `carousel-${this._uid}`
        }
    },
}
</script>

<style src="styles/components/carousel/Carousel.styl" lang="stylus"></style>
