<template>
    <div
        class="CarouselProgressInfo"
        :class="classModifiers"
    >
        <div class="CarouselProgressInfo-NumText">01</div>
        <div class="CarouselProgressInfo-Bar">
            <div class="CarouselProgressInfo-BarTrack">
                <div
                    class="CarouselProgressInfo-BarFill"
                    :style="barStyleModifiers"
                ></div>
            </div>
        </div>
        <div class="CarouselProgressInfo-NumText">{{ total | zeroPad }}</div>
    </div>
</template>

<script>
export default {
    name: 'CarouselProgressInfo',
    props: {
        total: {
            type: Number,
            default: 0,
        },
        current: {
            type: Number,
            default: 0,
        },
        light: {
            type: Boolean,
            default: false,
        },
        progress: {
            type: Number,
            default: 0,
        }
    },
    computed: {
        classModifiers() {
            return {
                'CarouselProgressInfo--light': this.light
            }
        },
        barStyleModifiers() {
            const percent = this.progress * 100
            return {
                width: `${percent}%`,
            }
        },
    }
}
</script>

<style src="styles/components/carousel/CarouselProgressInfo.styl" lang="stylus"></style>
