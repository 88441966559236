<template>
    <div class="GenericSectionGallery">
        <div class="item-transition wrapper-transition">
            <carousel
                class="GenericSectionGallery-Carousel"
                ref="carousel"
                :options="carouselOptions"
                @change="onSlideChange"
                @initialized="updateNavigator"
            >
                <carousel-slide
                    v-for="(slide, index) in slides"
                    :key="index"
                    :darken='true'
                >
                    <responsive-image
                        :mobileSrc="slide.background.image_desktop"
                        :desktopSrc="slide.background.image_desktop"
                        background
                    />
                </carousel-slide>
            </carousel>
            <div
                v-if="showHeaderPerSlide"
                class="GenericSectionGallery-Header"
            >
                <h1 class="item-transition">{{ slideTitle }}</h1>
                <h2 class="item-transition">{{ slideSubtitle }}</h2>
            </div>
            <div
                v-else
                class="GenericSectionGallery-Header"
            >
                <h1 class="item-transition">{{ title }}</h1>
                <h2 class="item-transition">{{ subtitle }}</h2>
            </div>
            <div class="GenericSectionGallery-Controls">
                <div class="GenericSectionGallery-NavWrapper item-transition">
                    <carousel-progress-info
                        class="GenericSectionGallery-Progress"
                        :total="slidesCount"
                        :current="slideIndex"
                        :progress="slidesProgress"
                    />
                    <carousel-navigator
                        class="GenericSectionGallery-Navigator"
                        :progressLabel="true"
                        @next="onNextClick"
                        @prev="onPrevClick"
                        :total="slidesCount"
                        :current="slideIndex"
                        light
                    />
                </div>
                <carousel
                    class="GenericSectionGallery-CarouselPreview"
                    ref="carouselPreview"
                    @slideClick="onPreviewClick"
                    :options="carouselPreviewOptions"
                >
                    <carousel-slide
                        class="GenericSectionGallery-PreviewSlide"
                        v-for="(slide, index) in slides"
                        :key="index"
                    >
                        <responsive-image
                            class="GenericSectionGallery-PreviewSlideImage"
                            :mobileSrc="slide.background.image_mobile"
                            :desktopSrc="slide.background.image_desktop"
                            background
                        />
                    </carousel-slide>
                </carousel>
            </div>
        </div>
    </div>
</template>

<script>
import inView from "in-view"
import CarouselProgressInfo from '@/app/components/carousel/CarouselProgressInfo.vue'
import CarouselNavigator from '@/app/components/carousel/CarouselNavigator.vue'
import Carousel from '@/app/components/carousel/Carousel.vue'
import CarouselSlide from '@/app/components/carousel/CarouselSlide.vue'
import ResponsiveImage from '@/app/components/ResponsiveImage.vue'

export default {
    name: 'GenericSectionGallery',
    components: {
        ResponsiveImage,
        CarouselProgressInfo,
        CarouselNavigator,
        CarouselSlide,
        Carousel,
    },
    data() {
        return {
            slidesCount: 0,
            slideIndex: 0,
            slidesProgress: 0,
            carouselPreviewOptions: {
                loop: true,
                slidesPerView: 3,
                spaceBetween: 10,
                initialSlide: 1,
                allowTouchMove: false,
                breakpoints: {
                    1450: {
                        slidesPerView: 2,
                    },
                    1024: {
                        slidesPerView: 1,
                    },
                    850: {
                        slidesPerView: 3,
                        spaceBetween: 10,
                    },
                },
            },
            carouselOptions: {
                loop: true,
            },
        }
    },
    props: {
        slides: {
            type: Array,
            default: () => [],
        },
        showHeaderPerSlide: {
            type: Boolean,
            default: false
        },
        title: {
            type: String,
            default: '',
        },
        subtitle: {
            type: String,
            default: '',
        }
    },
    mounted() {
        inView('.GenericSectionGallery .item-transition').on('enter', (el) => {
            el.classList.add("inview");
        });
    },
    updated() {
        this.updateNavigator()
    },
    methods: {
        onNextClick() {
            const { carousel } = this.$refs
            carousel.nextSlide()
        },
        onPrevClick() {
            const { carousel } = this.$refs
            carousel.prevSlide()
        },
        updateNavigator() {
            const { carousel } = this.$refs
            this.slideIndex = carousel.index + 1
            this.slidesCount = carousel.count
            this.slidesProgress = carousel.progress
        },
        onSlideChange(prevIndex, currIndex) {
            const { carouselPreview } = this.$refs
            carouselPreview.slideTo(currIndex + 1)
            this.updateNavigator()
        },
        onPreviewClick(index) {
            const { carousel } = this.$refs
            carousel.slideTo(index)
        }
    },
    computed: {
        slideTitle() {
            const { slideIndex } = this
            const index = Math.max(0, slideIndex - 1)
            return this.slides[index].content.title
        },
        slideSubtitle() {
            const { slideIndex } = this
            const index = Math.max(0, slideIndex - 1)
            return this.slides[index].content.subtitle
        },
    }
}
</script>

<style src="styles/components/sections/GenericSectionGallery.styl" lang="stylus"></style>
