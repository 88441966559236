import { mapState } from 'vuex'

export default {
    computed: {
        section() {
            const { $route, contents } = this;
            const re = new RegExp($route.path);
            return contents.sections.find(section => section.permalink.match(re));
        },
        page() {
            const { $route, contents } = this;
            const re = new RegExp($route.path);
            return contents.pages.find(page => page.permalink.match(re));
        },
        ...mapState({
            contents: state => state.app.contents
        })
    }
};
