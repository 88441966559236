<template>
    <generic-modal
        @closeClick="$emit('closeClick')"
        class="ThreeSixtyModal"
    >
        <div class="ThreeSixtyModal-FrameWrapper">
        <iframe
            :src="iframeSrc"
            frameborder="0"
            allowfullscreen="allowfullscreen"
        ></iframe>
        </div>
        <ul class="ThreeSixtyModal-NavList">
            <li
                v-for="(item, index) in items"
                :key="index"
                class="ThreeSixtyModal-NavListItem">
                <flat-button
                    class="ThreeSixtyModal-NavButton"
                    :light="index !== activeIndex"
                    :outline="index !== activeIndex"
                    @click.native="onThreeSixtyNavClick(index)"
                >{{ item.text }}</flat-button>
            </li>
        </ul>
    </generic-modal>
</template>

<script>
import GenericModal from '@/app/components/modals/GenericModal.vue'
import FlatButton from '@/app/components/buttons/FlatButton.vue'

export default {
    name: 'ThreeSixtyModal',
    data() {
        return {
            activeIndex: 0,
        }
    },
    components: {
        GenericModal,
        FlatButton,
    },
    props: {
        items: {
            type: Array,
            default: () => [],
        },
    },
    methods: {
        onThreeSixtyNavClick(index) {
            this.activeIndex = index
        },
    },
    computed: {
        iframeSrc() {
            const { items, activeIndex } = this
            return items[activeIndex].iframe_url
        }
    }
}
</script>

<style src="styles/components/modals/ThreeSixtyModal.styl" lang="stylus"></style>
