<template>
    <section v-if="data.slides.length > 0"
        :id="id"
        class="SectionAmenities"
        v-viewport-size:debounce.100='SectionUtils.viewport'
    >
        <generic-section-gallery
            :slides="data.slides"
            showHeaderPerSlide
        />
    </section>
</template>

<script>
import GenericSectionGallery from '@/app/components/sections/GenericSectionGallery.vue'
import SectionUtils from '@/app/mixins/SectionUtils'

export default {
    name: 'SectionAmenities',
    components: {
        GenericSectionGallery
    },
    mixins: [
        SectionUtils,
    ],
    props: {
        id: {
            type: String,
            required: true,
        },
        data: {
            type: Object,
            default: () => ({
                slides: [],
            })
        },
    },
}
</script>

<style src="styles/components/sections/SectionAmenities.styl" lang="stylus"></style>
