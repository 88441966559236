import Vue from 'vue'
import Api from '@/app/utils/Api'
import { xor } from 'lodash'
import { environment } from 'src/config'
import HomeTransform from '@/app/transforms/HomeTransform'
import PilarsTransform from '@/app/transforms/PilarsTransform'
import ComplexesTransform from '@/app/transforms/ComplexesTransform'
import SectionsTransform from '@/app/transforms/SectionsTransform'
import OldComplexesTransform from '@/app/transforms/OldComplexesTransform'
import PagesTransform from '@/app/transforms/PagesTransform'

export const LOCALE_CHANGED = 'locale/changed'
export const LOCALE_LOADING = 'locale/loading'
export const ASSET_LOADING = 'app/loading'
export const DATA = 'app/data'
export const PREV_ROUTE = 'app/prev-route'

export const LAYOUT_SHOW_FOOTER = 'layout/footer'
export const LAYOUT_HEADER_LOGO = 'layout/logo'
export const LAYOUT_SOCIAL_THEME = 'layout/social-theme'
export const LAYOUT_SCROLL_MENU = 'layout/scroll-menu'
export const LAYOUT_HIGHLIGHT_CONTENT = 'layout/highlight-section'
export const LAYOUT_SET = 'layout/set'

export const API_FETCH_HOME = 'api/fetch-home'
export const API_FETCH_COMPLEXES = 'api/fetch-complexes'
export const API_FETCH_COMPLEX = 'api/fetch-complex'
export const API_FETCH_PREVIOUS_WORKS = 'api/fetch-prev-works'
export const API_FETCH_SECTIONS = 'api/fetch-sections'
export const API_FETCH_PAGES = 'api/fetch-pages'
export const CONTENTS_SET = 'contents/set'
export const CONTENTS_LOADING = 'contents/set-loading'

const state = {
    public: environment.url.public,
    locale: '',
    locale_loading: false,
    loading: true,
    layout: {
        show_footer: true,
        show_scroll_menu: true,
        highlight: false,
        header_logo: '',
        social_theme: 'dark',
    },
    prev_route: '',
    data: null,
    contents: {},
    loadingContent: [],
    loadedContent: [],
}

async function CommitContents(namespace, { name, commit, getContent }) {
    const { loadingContent, loadedContent } = state
    if (loadingContent.includes(namespace)
        || loadedContent.includes(namespace)) {
        return false
    }
    let content = {}
    loadingContent.push(namespace)
    try {
        content = await getContent()
    } catch (e) {
        console.error(namespace, e)
    } finally {
        commit(CONTENTS_SET, { name, content })
        loadingContent.splice(loadingContent.indexOf(namespace), 1)
        loadedContent.push(namespace)
    }
}

const actions = {
    [LOCALE_LOADING]({ commit }) {
        commit(LOCALE_LOADING, true)
    },
    [LOCALE_CHANGED]({ commit }, locale) {
        commit(LOCALE_CHANGED, locale)
    },
    [ASSET_LOADING]({ commit }, payload) {
        commit(ASSET_LOADING, payload)
    },
    async [API_FETCH_HOME]({ commit }) {
        CommitContents(API_FETCH_HOME, {
            commit,
            name: 'home',
            async getContent() {
                const homePage = await Api.getPage('/')
                const pilars = await Api.getPilars()
                const homeTransform = HomeTransform.evaluate(homePage)
                const pilarsTransform = PilarsTransform.evaluate(pilars)
                return {
                    hero: homeTransform,
                    ...pilarsTransform,
                }
            }
        })
    },
    async [API_FETCH_COMPLEXES]({ commit }) {
        CommitContents(API_FETCH_COMPLEXES, {
            commit,
            name: 'complexes',
            async getContent() {
                const complexes = await Api.getComplexes()
                console.log("COMPLEJOS FROM API:", complexes)
                return ComplexesTransform.evaluate(complexes)
            },
        })
    },
    async [API_FETCH_SECTIONS]({ commit }) {
        CommitContents(API_FETCH_SECTIONS, {
            commit,
            name: 'sections',
            async getContent() {
                const sections = await Api.getSections()
                return SectionsTransform.evaluate(sections)
            }
        })
    },
    async [API_FETCH_PAGES]({ commit }) {
        CommitContents(API_FETCH_PAGES, {
            commit,
            name: 'pages',
            async getContent() {
                const pages = await Api.getPages()
                return PagesTransform.evaluate(pages)
            }
        })
    },
    async [API_FETCH_PREVIOUS_WORKS]({ commit }) {
        CommitContents(API_FETCH_PREVIOUS_WORKS, {
            commit,
            name: 'prev_works',
            async getContent() {
                const sections = await Api.getPreviousWorks()
                return OldComplexesTransform.evaluate(sections)
            }
        })
    },
    [LAYOUT_HEADER_LOGO]({ commit }, value) {
        commit(LAYOUT_SET, { name: 'header_logo', value })
    },
    [LAYOUT_SHOW_FOOTER]({ commit }, value) {
        commit(LAYOUT_SET, { name: 'show_footer', value })
    },
    [LAYOUT_SCROLL_MENU]({ commit }, value) {
        commit(LAYOUT_SET, { name: 'show_scroll_menu', value })
    },
    [LAYOUT_HIGHLIGHT_CONTENT]({ commit }, value) {
        commit(LAYOUT_SET, { name: 'highlight_content', value })
    },
    [LAYOUT_SOCIAL_THEME]({ commit }, value) {
        commit(LAYOUT_SET, { name: 'social_theme', value })
    },
    [PREV_ROUTE]({ commit }, value) {
        commit(PREV_ROUTE, value)
    }
}

const mutations = {
    [LOCALE_CHANGED](state, locale) {
        state.locale = locale
        state.locale_loading = false
    },
    [LOCALE_LOADING](state) {
        state.locale_loading = true
    },
    [ASSET_LOADING](state, payload) {
        state.loading = payload
    },
    [DATA](state, payload) {
        state.data = payload
    },
    [CONTENTS_SET](state, { name, content }) {
        Vue.set(state.contents, name, content)
    },
    [LAYOUT_SET](state, { name, value }) {
        Vue.set(state.layout, name, value)
    },
    [PREV_ROUTE](state, value) {
        state.prev_route = value
    },
}

const getters = {
    contentLoaded: (state) => {
        const { loadedContent } = state
        const sections = [
            API_FETCH_COMPLEXES,
            API_FETCH_SECTIONS,
            API_FETCH_HOME,
            API_FETCH_PAGES,
            API_FETCH_PREVIOUS_WORKS
        ]
        const xorArr = xor(loadedContent, sections)
        return xorArr.length === 0
    },
    locale: state => state.locale,
    public: state => state.public,
}

export default { state, actions, mutations, getters }
