<template>
    <div id="app" class="App" :class="highlightModifiers">
        <template v-if="started">
            <layout-header></layout-header>
            <layout-main></layout-main>
            <layout-footer></layout-footer>
            <layout-social></layout-social>
            <layout-site-menu
                :items="topMenuItems"
                light
                @visibility-change="onNavVisibiltyChange"
            ></layout-site-menu>
            <!-- LayoutMenu is only visible if LayoutNav doesn't fit in view -->
            <template v-if="!isNavVisible">
                <layout-section-menu
                    v-if="isMenuVisible"
                    :items="mobileMenuItems"
                    :complexes="complexesItems"
                    @close="closeMenu"
                ></layout-section-menu>
                <layout-menu-btn
                    v-else
                    light
                    @click="openMenu"
                ></layout-menu-btn>
            </template>
        </template>
        <preloader v-if="!started" transitionMode="out-in"></preloader>
    </div>
</template>
<script>
import { mapState } from 'vuex'
import isMobile from 'ismobilejs'
import axios from 'axios'
import { environment } from '../config/index'
import Preloader from './components/base/Preloader.vue'
import LayoutHeader from './components/layout/LayoutHeader.vue'
import LayoutMain from './components/layout/LayoutMain.vue'
import LayoutFooter from './components/layout/LayoutFooter.vue'
import LayoutSocial from './components/layout/LayoutSocial.vue'
import LayoutSiteMenu from './components/layout/LayoutSiteMenu.vue'
import LayoutSectionMenu from './components/layout/LayoutSectionMenu.vue'
import LayoutMenuBtn from './components/layout/LayoutMenuBtn.vue'
import ContentHighlight from './mixins/ContentHighlight'

export default {
    name: 'App',
    components: {
        Preloader,
        LayoutHeader,
        LayoutMain,
        LayoutFooter,
        LayoutSocial,
        LayoutSiteMenu,
        LayoutSectionMenu,
        LayoutMenuBtn,
    },
    data() {
        return {
            topMenuItems: [],
            siteMenuItems: {},
            mobileMenuItems: {},
            complexesItems: {},
            isNavVisible: false,
            isMenuVisible: false,
        }
    },
    mixins: [
        ContentHighlight,
    ],
    methods: {
        openMenu() {
            this.isMenuVisible = true
        },
        closeMenu() {
            this.isMenuVisible = false
        },
        onNavVisibiltyChange(e) {
            this.isNavVisible = e.isVisible
        },
    },
    mounted() {
        axios.get(`${environment.url.api}/menu-items`)
            .then((data) => {
                this.topMenuItems = data.data.top_menu.items;
                this.siteMenuItems = data.data.site_menu.items;
                this.mobileMenuItems = data.data.mobile_menu.items;
                this.complexesItems = data.data.complexes.items;
            })
    },
    computed: {
        ...mapState({
            started: (state, getters) => state.app.loading === false && getters.contentLoaded,
        }),
        isPhone() {
            return isMobile.phone;
        },
        classModifiers() {
            const { isPrerender } = this
            return {
                'App-SsrPrerender': isPrerender,
            }
        }
    },
}
</script>

<style src="styles/views/Root.styl" lang="stylus"></style>
