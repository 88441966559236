<template>
    <header class="LayoutHeader">
        <a
            class="LayoutHeader-Logo"
            href="/">
            <img
                v-if="customLogo"
                :src="logoUrl"
                alt="Be Grand"
            >
            <img
                v-else
                src="~assets/img/01begrand.svg"
                alt="Be Grand"
            >
        </a>
    </header>
</template>

<script>
import { mapState } from 'vuex'

export default {
    name: 'LayoutHeader',
    data() {
        return {
            customLogo: false,
            logoUrl: '',
        }
    },
    computed: {
        ...mapState({
            header_logo: state => state.app.layout.header_logo,
        })
    },
    watch: {
        header_logo(value) {
            if (value === '') {
                this.customLogo = false
            } else {
                this.customLogo = true
                this.logoUrl = value
            }
        }
    },
}
</script>

<style src="styles/components/layout/LayoutHeader.styl" lang="stylus"></style>
