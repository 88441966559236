<template>
    <div
        class="DragHint"
        :class="classModifiers"
    >
        <div class="DragHint-Text">Arrastra</div>
        <div class="DragHint-Icon"></div>
        <div class="DragHint-Line"></div>
    </div>
</template>

<script>
export default {
    name: 'DragHint',
    props: {
        light: {
            type: Boolean,
            default: false,
        },
        hideOnMobile: {
            type: Boolean,
            default: false,
        }
    },
    computed: {
        classModifiers() {
            return {
                'DragHint--Light': this.light,
                'DragHint--HideOnMobile': this.hideOnMobile,
            }
        }
    }
}
</script>

<style src="styles/components/DragHint.styl" lang="stylus"></style>
