<template>
    <section
        class="Complex"
        v-ssr-intercept="onSsrIntercept"
    >
        <template v-for="(section, index) in sections">
            <intersect
                :key="index"
                :threshold="[0.5]"
                @enter="onSectionEnter(section)"
            >
                <div
                    :id="section.data.id"
                    :is="section.type"
                    :key="index"
                    :data="section.data"
                ></div>
            </intersect>
        </template>
        <view-nav
            :items="navSections"
            :item-active="activeSection"
            @item-click="scrollTo"
            light
        ></view-nav>
    </section>
</template>

<script>
import { LAYOUT_SOCIAL_THEME } from '@/app/store/modules/app'
import 'intersection-observer'
import Intersect from 'vue-intersect'
import ViewNav from '@/app/components/navs/ViewNav.vue'
import GenericSection from '@/app/components/sections/GenericSection.vue'
import SectionApartments from '@/app/components/sections/SectionApartments.vue'
import SectionAmenities from '@/app/components/sections/SectionAmenities.vue'
import SectionComplexHero from '@/app/components/sections/SectionComplexHero.vue'
import SectionMap from '@/app/components/sections/SectionMap.vue'
import SectionSchedule from '@/app/components/sections/SectionSchedule.vue'
import ComplexUtils from '@/app/mixins/ComplexUtils'
import MixHead from '@/app/mixins/MixHead'

export default {
    name: 'Complex',
    mixins: [
        ComplexUtils,
        MixHead,
    ],
    components: {
        SectionMap,
        SectionSchedule,
        SectionApartments,
        SectionAmenities,
        GenericSection,
        SectionComplexHero,
        ViewNav,
        Intersect,
    },
    data() {
        return {
            activeSection: null,
            restoreScroll: true,
        }
    },
    mounted() {
        this.readyToRender()
        this.$store.dispatch(LAYOUT_SOCIAL_THEME, 'light');
    },
    methods: {
        onSectionEnter(section = '') {
            this.activeSection = section.data.id
        },
    },
    computed: {
        navSections() {
            return this.sections.map(section => section.data);
        },
        sections() {
            const { $route, complex, complexes } = this
            console.log("THIS", this)
            return [
                { data: complex.hero, type: 'section-complex-hero' },
                { data: complex.apartments, type: 'section-apartments' },
                { data: complex.amenities, type: 'section-amenities' },
                { data: complex.map, type: 'section-map' },
                { data: complex.schedule, type: 'section-schedule' },
            ]
        },
        metas() {
            const { $route, complex, complexes } = this
            return complex.meta_tags
        }
    }
}
</script>

<style src="styles/views/Complex.styl" lang="stylus"></style>
