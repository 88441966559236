export default {
    data() {
        return {
            sections: [
                { id: "home", menu_name: "Home", router_to: '/' },
                { id: "about", menu_name: "Quiénes Somos", router_to: '/quienes-somos' },
                { id: "foundation", menu_name: "Fundación Be Grand", router_to: '/fundacion' },
                { id: "sustainability", menu_name: "Sustentabilidad", router_to: '/sustentabilidad' },
            ]
        };
    }
};
