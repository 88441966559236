<template>
    <section
        class="ComplexGallery"
        v-viewport-size:debounce.100='SectionUtils.viewport'
    >
        <generic-section-gallery
            :slides="slides"
            title="Galería"
            :subtitle='complex.name'
        />
        <flat-button
            class="ComplexGallery-BackButton"
            :to="backRoute"
            el="router-link"
            outline
        >
            Volver
        </flat-button>
    </section>
</template>

<script>
import { LAYOUT_SOCIAL_THEME } from '@/app/store/modules/app'
import GenericSectionGallery from '@/app/components/sections/GenericSectionGallery.vue'
import ComplexUtils from '@/app/mixins/ComplexUtils'
import SectionUtils from '@/app/mixins/SectionUtils'
import FlatButton from '@/app/components/buttons/FlatButton.vue'

export default {
    name: 'ComplexGallery',
    components: {
        GenericSectionGallery,
        FlatButton,
    },
    mixins: [
        ComplexUtils,
        SectionUtils,
    ],
    mounted() {
        this.$store.dispatch(LAYOUT_SOCIAL_THEME, 'light');
    },
    computed: {
        slides() {
            const { complex } = this
            return complex.apartments_gallery.items
        },
        backRoute() {
            const { $route } = this
            return { name: 'complex', params: { complex: $route.params.complex }, hash: '#tipos' }
        }
    },
}
</script>

<style src="styles/views/ComplexGallery.styl" lang="stylus"></style>
