<template>
    <section
        :id="id"
        class="GenericSectionHero"
        v-viewport-size:debounce.100='viewport'
    >
        <carousel
            ref="carousel"
            v-if="initialized"
            @afterLeave="onSlideLeave"
            @change="onSlideChange"
            @slideClick="onSlideClick"
        >
            <carousel-slide
                v-for="(slide, index) in this.slides()"
                :key="index"
            >
                <!-- Render slide background -->
                <responsive-video
                    :ref="`video_${index}`"
                    v-if="getType() === 'desktop' &&  slide.background.video && slide.background.video !== '/'"
                    :src="slide.background.video"
                    :srcMobile="slide.background.video_mobile"
                    :mobilePosterSrc="slide.background.image_mobile"
                    :desktopPosterSrc="slide.background.image_desktop"
                    :autoplay="true"
                    :repeat="2"
                    @play="onVideoStart(index)"
                    @ended="onVideoEnded(index)"
                    @pause="onVideoEnded(index)"
                >
                </responsive-video>
                <responsive-video
                    :ref="`video_${index}`"
                    v-else-if="getType() === 'mobile' &&  slide.background.video_mobile && slide.background.video_mobile !== '/'"
                    :srcMobile="slide.background.video_mobile"
                    :mobilePosterSrc="slide.background.image_mobile"
                    :desktopPosterSrc="slide.background.image_desktop"
                    :autoplay="true"
                    :repeat="2"
                    @play="onVideoStart(index)"
                    @ended="onVideoEnded(index)"
                    @pause="onVideoEnded(index)"
                >
                </responsive-video>
                <responsive-image
                    v-else
                    class="GenericSectionHero-Image"
                    :mobileSrc="slide.background.image_mobile"
                    :desktopSrc="slide.background.image_desktop"
                    background
                ></responsive-image>
                <div
                    class="GenericSectionHero-DarkLayer"
                    v-if="shouldShowContent(index)"
                ></div>
                <!-- Render slide contents -->
                <transition
                    :css="false"
                    appear
                >
                    <div
                        ref="contents"
                        v-if="shouldShowContent(index)"
                        :style="contentStyle(index)"
                        class="GenericSectionHero-Content"
                    >
                        <slot
                            :data="data"
                            :slide="slide"
                            :index="index"
                            :state="states[index]"
                            :playVideo="playVideo"
                        ></slot>
                    </div>
                </transition>
            </carousel-slide>
        </carousel>
        <carousel-navigator
            class="GenericSectionHero-Navigator"
            v-if="slidesCount > 1"
            @next="onNextSlide"
            @prev="onPrevSlide"
            :total="slidesCount"
            :current="slideIndex"
            light
        />
    </section>
</template>

<script>
import { findKey } from 'lodash';
import CarouselNavigator from '@/app/components/carousel/CarouselNavigator.vue'
import Carousel from '@/app/components/carousel/Carousel.vue'
import CarouselSlide from '@/app/components/carousel/CarouselSlide.vue'
import ResponsiveImage from '@/app/components/ResponsiveImage.vue'
import ResponsiveVideo from '@/app/components/ResponsiveVideo.vue'
import DragHint from '@/app/components/DragHint.vue'

let id = 0;

export default {
    name: 'GenericSectionHero',
    data() {
        return {
            viewport: {
                height: 100,
            },
            slidesCount: 0,
            slideIndex: 0,
            states: [],
            initialized: false,
        }
    },
    components: {
        CarouselNavigator,
        ResponsiveImage,
        ResponsiveVideo,
        Carousel,
        CarouselSlide,
        DragHint,
    },
    props: {
        id: {
            type: String,
            /* eslint-disable-next-line */
            default: () => `carousel-${id++}`
        },
        data: {
            type: Object,
            default: () => ({
                slides: [],
            })
        },
        showDragHint: {
            type: Boolean,
            default: false,
        }
    },
    mounted() {
        this.initStates()
        this.initialized = true
    },
    updated() {
        this.updateNavigator()
    },
    methods: {
        slides() {
            const slides = this.setSlides();
            return slides;
        },
        setSlides() {
            let campaign_id = window.location.search.split("?campaign=");
            let slides = this.data.slides;
            let firstSlide = this.data.slides[0];
            if (campaign_id[1]) {
                // Si hay una campaña activa en 'params', buscamos el slide con el id de campaña
                for (var i = 0; i < slides.length; i++) {
                    let _slide = slides[i];
                    if (_slide.campaign_id == campaign_id[1]) {
                        slides = [_slide];
                        break;
                    }
                }
            } else {
                // Si no hay campaña en params, quitamos todos los slides con campaña id
                for (var i = 0; i < slides.length; i++) {
                    let _slide = slides[i];
                    if (_slide.campaign_id) {
                        let _index = slides.indexOf(_slide);
                        slides.splice(_index, 1);
                    }
                }
            }
            // El primer slide necesita del objeto content como propiedad,
            // algunos nodos de campaña no tienen, asignamos default para prevenir errores
            if (!slides[0].content) {
                slides[0].content = {
                    name: firstSlide.content.name,
                    subtitle: firstSlide.content.subtitle,
                    title: firstSlide.content.title,
                    title_image: firstSlide.content.title_image,
                    description: firstSlide.content.description
                }
            }
            return slides;
        },
        getType() {
            const { $mq } = this;
            return $mq;
        },
        onNextSlide() {
            const { carousel } = this.$refs
            carousel.nextSlide()
        },
        onPrevSlide() {
            const { carousel } = this.$refs
            carousel.prevSlide()
        },
        updateNavigator() {
            const { carousel } = this.$refs
            if (carousel) {
                this.slideIndex = carousel.index + 1
                this.slidesCount = carousel.count
            }
        },
        onSlideClick(index) {
            const { slides } = this.data
            const { $router } = this
            const slide = slides[index]
            let _link = slide.background.path;
            if (_link) {
                if (_link.indexOf("http") >= 0) {
                    window.open(_link);
                } else {
                    $router.push({ path: _link })
                }
            }
        },
        getVideo(id) {
            const ref = this.$refs[`video_${id}`]
            return Array.isArray(ref) ? ref[0] : ref
        },
        sort(object) {
            let newObject = {};
            let keys = [];
            for (let key in object) keys.push(key);
            for (let i = keys.length - 1; i >= 0; i--) {
                let value = object[keys[i]];
                newObject[keys[i]]= value;
            }
            return newObject;
        },
        initStates() {
            let { slides } = this.data;
            /*let { slides } = this.setSlides();*/
            this.states = slides.map(() => ({
                showVideo: false,
                loading: false,
            }))
        },
        shouldShowContent(index) {
            const { data } = this
            return data.slides[index].content
        },
        onSlideChange(_, curr) {
            this.playVideo(curr)
            this.updateNavigator()
        },
        onSlideLeave(index) {
            this.stopVideo(index)
        },
        playVideo(index) {
            this.states[index].loading = true
            const video = this.getVideo(index)
            if (video) {
                video.play()
            }
        },
        stopVideo(index) {
            const video = this.getVideo(index)
            if (video) {
                video.stop()
            }
        },
        onVideoStart(index) {
            this.states[index].loading = false
            this.states[index].showVideo = true
        },
        onVideoEnded(index) {
            this.states[index].showVideo = false
        },
        contentStyle(index) {
            const { states } = this
            const playing = states[index].showVideo
            return {
                opacity: playing ? 0.8 : 1
            }
        }
    },
}
</script>

<style src="styles/components/sections/GenericSectionHero.styl" lang="stylus"></style>
