<template>
    <section
        class="Complexes"
    >
        <div class="ComplexesHeader">
            <responsive-image
                class="ComplexesHeader-Cover"
                :desktopSrc="page.images.top.image_desktop"
                :mobileSrc="page.images.top.image_mobile"
                background
            >
                <div class="ComplexesHeader-HeaderMobile">
                    <h1>Be Grand</h1>
                    <h2>Desarrollos</h2>
                </div>
            </responsive-image>
            <div class="ComplexesHeader-HeaderDesktop">
                <flat-button
                    el='router-link'
                    class="Complexes-BackButton"
                    to= '/'
                    outline
                >Volver</flat-button>
                <h1>Desarrollos</h1>
            </div>
        </div>
        <complexes-grid
            class="Complexes-Grid"
            :items="items"
        >
        </complexes-grid>
    </section>
</template>

<script>
import { mapState } from 'vuex'
import { LAYOUT_SOCIAL_THEME } from '@/app/store/modules/app'
import ComplexesGrid from '@/app/components/ComplexesGrid.vue'
import SectionPage from '@/app/mixins/SectionPage'
import MixHead from '@/app/mixins/MixHead'
import MixHomeSections from '@/app/mixins/MixHomeSections'
import FlatButton from '@/app/components/buttons/FlatButton.vue'
import SectionUtils from '@/app/mixins/SectionUtils'
import ResponsiveImage from '@/app/components/ResponsiveImage.vue'

export default {
    name: 'Complexes',
    components: {
        ResponsiveImage,
        ComplexesGrid,
        FlatButton,
    },
    mixins: [
        MixHead,
        SectionPage,
        SectionUtils,
        MixHomeSections,
    ],
    mounted() {
        this.$store.dispatch(LAYOUT_SOCIAL_THEME, 'dark');
    },
    computed: {
        ...mapState({
            contents: state => state.app.contents,
        }),
        metas() {
            const { page } = this
            return page.meta_tags
        },
        items() {
            const { complexes } = this.contents
            const cpx = complexes.filter(item => item.archive_entry.path !== null)
            return cpx.map(complex => complex.archive_entry)
        }
    },
}
</script>

<style src="styles/views/Complexes.styl" lang="stylus"></style>
