<template>
    <div
        class="ProgressBar"
        :class="modifiers"
    >
        <div
            class="ProgressBar-value"
            :style="valueStyle"
        />

    </div>
</template>

<script>
export default {
    name: 'ProgressBar',
    props: {
        value: {
            type: Number,
            required: true,
            validator(value) {
                if (value < 0 || value > 1) {
                    console.error(`Invalid ProgressBar value ${value}.`)
                    return false
                }
                return true
            },
        },
        light: {
            type: Boolean,
            default: false,
        },
    },
    computed: {
        valueStyle() {
            const { value } = this
            return {
                width: `${value * 100}%`,
            }
        },
        modifiers() {
            const { light } = this
            return {
                'ProgressBar--light': light,
            }
        },
    },
}
</script>

<style src="styles/components/ProgressBar.styl" lang="stylus"></style>
