import Vue from "vue";
import AbstractApp from "foo/core/AbstractApp";
import store from "@/app/store";
import LocaleManager from "foo/core/locale/LocaleManager";
import es from 'vee-validate/dist/locale/es';

// VUE PLUGINS
import VueFoo from "foo/core/VueFoo";
import VueAnalytics from 'vue-analytics';
import VeeValidate from "vee-validate";
import VueMq from 'vue-mq';
import VueHead from '@/app/plugins/VueHead';
import ScrollTo from '@/app/plugins/ScrollTo';
import SsrHandler from '@/app/plugins/SsrHandler';

// DIRECTIVES
import SsrIntercept from '@/app/directives/SsrIntercept';
import ViewportSizeDirective from "./directives/ViewportSize";
import TextBreakpointsDirective from './directives/TextBreakpoints';
import ScrollMonitorDirective from './directives/ScrollMonitor';

// VUE ROUTER
import VueRouter from "vue-router";
import { sync } from "vuex-router-sync";
import router from "@/app/router";

import Root from "@/app/Root.vue";

// FILTERS
import "@/app/filters/zeroPad"

Vue.directive('viewport-size', ViewportSizeDirective);
Vue.directive('ssr-intercept', SsrIntercept);
Vue.directive('text-breakpoints', TextBreakpointsDirective);
Vue.directive('scroll-monitor', ScrollMonitorDirective);

Vue.config.performance = process.env.NODE_ENV !== "production";
Vue.config.devtools = true;
export default class App extends AbstractApp {
    constructor() {
        super();
        Vue.use(VueRouter);
        Vue.use(VueFoo);
        Vue.use(VueAnalytics, {
            id: 'UA-122236422-1',
        });
        Vue.use(SsrHandler)
        Vue.use(ScrollTo);
        Vue.use(VueMq, {
            breakpoints: {
                mobile: 850,
                desktop: Infinity,
            }
        });
        Vue.use(VueHead);
        Vue.use(VeeValidate, { locale: 'es' });
        VeeValidate.Validator.localize('es', es);
    }

    renderApp() {
        this.router = router;
        router.beforeEach((to, from, next) => {
            next();
        });
        sync(store, router);
        const { i18n } = LocaleManager;
        /* eslint-disable no-new */
        new Vue({
            el: "#app",
            store,
            router,
            i18n,
            render: h => h(Root)
        });
    }
}
