<template>
    <generic-modal v-if="items.image"
        @closeClick="handleCloseClick"
        class="HomeModal"
    >
        <a href="https://preventa.begrand.mx">
            <img :src="items.image" class="Bgran"/>
       </a>
       <!--<div id="textId 1">
            <h1>
                {{items.title}}
            </h1>
            <div v-html="items.description"></div>
        </div>-->
    </generic-modal>
</template>

<script>
import GenericModal from '@/app/components/modals/GenericModal.vue'

export default {
    name: 'HomeModal',
    data(){
        return {}
    },
    props: {
        items: {},
    },
    components: {
        GenericModal,
    },
    methods: {
      handleCloseClick() {
        const modalHome = document.querySelector('.GenericModal.HomeModal');
        const padre = event.target.parentNode;
        modalHome.classList.add('close-modal');
      }
    },
    computed: {},
    mounted() {
        console.log('Hola');
    },
}
</script>
<style>
    .Bgran{
        /*height: 100%;*/
        object-fit: cover;
        width: 100%;
    }
</style>


<style src="styles/components/modals/HomeModal.styl" lang="stylus"></style>
