/**
 * @param {number} index
 * @param {number} numberOfSlides
 */
function getInfiniteIndex(index, numberOfSlides) {
    if (index === 0) return numberOfSlides
    if (index === numberOfSlides + 1) return 1
    return index
}

/**
 * @param {number} index
 * @param {number} numberOfSlides
 */
function getFiniteIndex(index, numberOfSlides) {
    if (index < 1) return 1
    return Math.min(index, numberOfSlides)
}

/**
 * @param {number} index
 * @param {number} numberOfSlides
 * @param {boolean} infinite
 */
function getIndex(index, numberOfSlides, infinite) {
    if (infinite) return getInfiniteIndex(index, numberOfSlides)
    return getFiniteIndex(index, numberOfSlides)
}

export default {
    data() {
        return {
            MixCrsl: {
                activeSlide: 1,
                numberOfSlides: 1,
                infinite: true,
                direction: 'forwards',
            },
        }
    },
    methods: {
        /**
         * @param {number} index
         */
        MixCrslIsActive(index) {
            const { MixCrsl } = this
            return MixCrsl.activeSlide === index
        },
        MixCrslUpdate(index) {
            const { MixCrsl } = this
            MixCrsl.activeSlide = getIndex(
                index,
                MixCrsl.numberOfSlides,
                MixCrsl.infinite
            )
        },
        /**
         * @param {Number} index
         */
        MixCrslGoto(index) {
            const { MixCrsl } = this
            if (index > MixCrsl.activeSlide) this.MixCrslNext()
            else this.MixCrslBack()
        },
        MixCrslBack() {
            const { MixCrsl } = this
            MixCrsl.direction = 'backwards'
            this.MixCrslUpdate(MixCrsl.activeSlide - 1)
        },
        MixCrslNext() {
            const { MixCrsl } = this
            MixCrsl.direction = 'forwards'
            this.MixCrslUpdate(MixCrsl.activeSlide + 1)
        },
    },
    computed: {
        MixCrslBackDisabled() {
            const { MixCrsl } = this
            if (MixCrsl.infinite) return false
            return MixCrsl.activeSlide === 1
        },
        MixCrslNextDisabled() {
            const { MixCrsl } = this
            if (MixCrsl.infinite) return false
            return MixCrsl.activeSlide === MixCrsl.numberOfSlides
        },
    },
}
