<template>
    <fixed-nav
        class="LayoutSiteMenu"
        :class="modifiers"
        @visibility-change="$emit('visibility-change', $event)"
    >
        <nav class="LayoutSiteMenu-container">
            <ul class="LayoutSiteMenu-list">
                <li
                    class="LayoutSiteMenu-item"
                    v-for="item in items"
                    :key="item.path"
                    style=""
                        >
                    <a
                        v-if="item.path"
                        class="LayoutSiteMenu-link"
                        style="text-decoration:none"
                        target="_self"
                    >
                        {{ item.name }}
                    </a>
                    <a
                        v-else
                        class="LayoutSiteMenu-link"
                        :href="item.href"
                        :target="item.target"
                    > {{ item.name }}</a>
                    <ul
                        class="LayoutSiteMenu-Submenu"
                        v-if="item.submenu"
                    >
                        <li style="" class="LayoutSiteMenu-Submenu-Item" style="text-transform: uppercase;">
                        México
                        <span style=""><img style="height:25px;" src="~assets/img/mex.png"></span>
                        </li>
                        <li
                            class="LayoutSiteMenu-Submenu-Item"
                            v-for="(item, index) in item.submenu"
                            :key="index"
                        >
                            <a
                                v-if="item.path"
                                :href="item.path"
                                target="_self"
                                class="LayoutSiteMenu-link"
                            >
                                {{ item.name }}
                            </a>
                        </li>
                        <li class="LayoutSiteMenu-Submenu-Item">
                            <a target="_blank" href="https://begrand.es/" style="text-transform: uppercase;" >
                                España
                                <span style="">
                                    <img style="height:25px;" src="~assets/img/spain.png">
                                </span>
                            </a>
                        </li>
                    </ul>
                </li>
            </ul>
            <flat-button
                v-if="showContactButton"
                :to="buttonTarget"
                el="router-link"
                class="LayoutSiteMenu-btn"
                :light="light"
                small
            >
                Contacto
            </flat-button>
        </nav>
    </fixed-nav>
</template>

<script>
import FixedNav from '@/app/components/navs/FixedNav.vue'
import FlatButton from '@/app/components/buttons/FlatButton.vue'

export default {
    name: 'LayoutSiteMenu',
    components: {
        FixedNav,
        FlatButton,
    },
    props: {
        items: {
            type: Array,
            required: true,
        },
        light: {
            type: Boolean,
            default: false,
        },
    },
    computed: {
        modifiers() {
            const { light } = this
            return {
                'LayoutSiteMenu--light': light,
            }
        },
        buttonTarget() {
            const { params } = this.$route
            return {
                name: 'schedule',
                params: {
                    complex: params.complex,
                },
            }
        },
        showContactButton() {
            const { params } = this.$route
            return params.complex !== undefined
        },
    },
}
</script>

<style src="styles/components/layout/LayoutSiteMenu.styl" lang="stylus"></style>
