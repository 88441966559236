import { mapState } from 'vuex'
import { LAYOUT_HEADER_LOGO } from '@/app/store/modules/app'

export default {
    mounted() {
        this.$store.dispatch(LAYOUT_HEADER_LOGO, this.complex.header_logo)
    },
    methods: {
    },
    computed: {
        complex() {
            const { $route, contents } = this
            const re = new RegExp($route.params.complex)
            return contents.complexes.find(complex => complex.permalink.match(re))
        },
        ...mapState({
            complexes: state => state.app.contents.complexes,
            contents: state => state.app.contents,
        }),
    },
};
