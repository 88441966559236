<template>
    <section
        class="GenericSection"
        :class="classModifiers"
        :id="id"
        v-viewport-size:debounce.100='SectionUtils.viewport'
    >
        <responsive-image
            class="GenericSection-Image"
            :mobileSrc="data.background.image_mobile"
            :desktopSrc="data.background.image_desktop"
            background
        />

        <div class="GenericSection-Content">
            <h1
                :style="accentColorStyle"
                v-text-breakpoints='TextUtils.breakpoints.h1'
            >{{ data.content.title }}</h1>
            <h2 v-text-breakpoints='TextUtils.breakpoints.h2'>{{ data.content.subtitle }}</h2>
            <hr>
            <div v-html="data.content.description"></div>
            <flat-button
                v-if="data.button.show"
                class="GenericSection-NextButton"
                :el="data.button.target === '_blank' ? 'a' : 'router-link'"
                :target="data.button.target"
                iconId="icon-arrow-right"
                :href="data.button.to"
                :to="data.button.to"
            >
                {{ data.button.text }}
            </flat-button>
            <template v-if="showHint">
                <div class="GenericSection-TopStripe">
                    <slot name="stripe-content">
                        <div class="GenericSection-Hint" v-html="data.hint"></div>
                    </slot>
                </div>
            </template>
        </div>
        <template v-if="showHint">
            <div class="GenericSection-BottomStripe"></div>
        </template>
        <slot></slot>
    </section>
</template>

<script>
import FlatButton from '@/app/components/buttons/FlatButton.vue'
import ResponsiveImage from '@/app/components/ResponsiveImage.vue'
import SectionUtils from '@/app/mixins/SectionUtils'
import TextUtils from '@/app/mixins/TextUtils'

export default {
    name: 'GenericSection',
    components: {
        FlatButton,
        ResponsiveImage,
    },
    mixins: [
        TextUtils,
        SectionUtils,
    ],
    props: {
        id: {
            type: String,
            required: true,
        },
        data: {
            type: Object,
            default: () => ({
                accentColor: '',
                align: 'left',
                background: {
                    mode: 'framed',
                    image_mobile: '',
                    image_desktop: '',
                },
                hint: '',
                content: {
                    title: '',
                    subtitle: '',
                    description: '',
                },
                button: {
                    show: false,
                    target: '_self',
                    to: { name: 'home' },
                    text: '',
                },
            })
        },
    },
    computed: {
        showHint() {
            const { background, hint } = this.data
            return background.mode === 'framed' && hint !== ''
        },
        accentColorStyle() {
            return { color: this.data.accentColor }
        },
        classModifiers() {
            const { align, background } = this.data;
            return {
                [`GenericSection--${align}`]: true,
                [`GenericSection--${background.mode}`]: true,
            }
        }
    }
}
</script>

<style src="styles/components/sections/GenericSection.styl" lang="stylus"></style>
