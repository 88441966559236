<template>
    <transition
        :css="false"
        :appear="true"
        @appear="onAppear"
        @enter="onEnter"
        @leave="onLeave"
    >
        <ul class="MenuPane">
            <li
                class="MenuPane-item"
                v-for="item in items"
                :key="item.path"
            >
                <a
                    :href="item.path"
                    class="MenuPane-link">
                    {{ item.name }}
                </a>
            </li>
        </ul>
    </transition>
</template>

<script>
export default {
    name: 'MenuPane',
    props: {
        items: {
            type: Array,
            required: true,
        },
        animDirection: {
            type: String,
            required: true,
        },
        showExtra: {
            type: Boolean,
            required: true,
        },
        showExtraSp: {
            type: Boolean,
            required: true,
        },
        showExtraSpComplex: {
            type: Boolean,
            required: true,
        },
    },
    data() {
        return {
            height: null,
        }
    },
    methods: {
        onItemClick() {
            this.$emit('item-click')
            this.scrollToHash(true)
        },
        bind() {
            window.addEventListener('resize', this.updateHeight)
        },
        unbind() {
            window.removeEventListener('resize', this.updateHeight)
        },
        updateHeight() {
            const height = this.$el.offsetHeight
            // Just emit if the height actually changed
            if (height !== this.height) {
                this.$emit('height-update', {
                    height,
                })
            }
        },
        onAppear(el, done) {
            // Abort if element is hidden
            if (el.style.display === 'none') {
                done()
                return
            }
            // Bind events here and not on mounted
            this.bind()
            // Emit initial height
            this.updateHeight()
            // Appearing animation doesn't have direction
            TweenLite.fromTo(el, 0.25, {
                alpha: 0,
            }, {
                alpha: 1,
                onComplete: done,
            })
        },
        onEnter(el, done) {
            const { animDirection } = this
            // Bind events here and not on mounted
            this.bind()
            // Animate with direction
            TweenLite.fromTo(el, 0.25, {
                alpha: 0,
                x: animDirection === 'forwards' ? '20px' : '-20px',
            }, {
                alpha: 1,
                x: '0%',
                delay: 0.25,
                ease: Cubic.easeOut,
                onComplete: done,
                onStart: this.updateHeight,
            })
        },
        onLeave(el, done) {
            const { animDirection } = this
            // Bind events here and not on beforeDestroy
            this.unbind()
            // Animate with direction
            TweenLite.to(el, 0.25, {
                alpha: 0,
                x: animDirection === 'forwards' ? '-20px' : '20px',
                ease: Cubic.easeOut,
                onComplete: done,
            })
        },
    },
}
</script>

<style src="styles/components/MenuPane.styl" lang="stylus"></style>
