export default {
    install(Vue) {
        Vue.mixin({
            mounted() {
                if (!this.isPrerender) {
                    document.body.classList.remove('SsrPrerender')
                }
            },
            data() {
                return {
                    ssrReady: false,
                    onSsrResolveCallback: null,
                }
            },
            methods: {
                async onSsrIntercept() {
                    return new Promise((resolve) => {
                        if (this.ssrReady) {
                            resolve()
                        } else {
                            this.onSsrResolveCallback = resolve
                        }
                    })
                },
                readyToRender(delay = 0) {
                    if (!this.ssrReady) {
                        this.ssrReady = true
                        if (this.onSsrResolveCallback) {
                            setTimeout(() => {
                                this.$nextTick(this.onSsrResolveCallback)
                            }, delay)
                        }
                    }
                },
            },
            computed: {
                isPrerender() {
                    // eslint-disable-next-line no-underscore-dangle
                    return window.__SSR_IS_PRENDERER;
                },
            }
        });
    }
};
