<template>
    <button :is="el" :to="toRoute" :href="href" :target="target" :class="modifiers" class="FlatButton">
        <svg v-if="hasIcon && iconLeft" class="FlatButton-icon">
            <use :xlink:href="`#${iconId}`"/>
        </svg>
        <span class="FlatButton-text">
            <slot></slot>
        </span>
        <svg v-if="hasIcon && !iconLeft" class="FlatButton-icon">
            <use :xlink:href="`#${iconId}`"/>
        </svg>
    </button>
</template>

<script>
export default {
    name: 'FlatButton',
    props: {
        el: {
            type: String,
            default: 'button',
        },
        to: {
            type: [Object, String],
            default: null,
        },
        target: {
            type: String,
        },
        href: {
            type: String,
        },
        iconId: {
            type: String,
        },
        light: {
            type: Boolean,
            default: false,
        },
        small: {
            type: Boolean,
            default: false,
        },
        iconLeft: {
            type: Boolean,
            default: false,
        },
        outline: {
            type: Boolean,
            default: false,
        },
        disabled: {
            type: Boolean,
            default: false,
        },
    },
    computed: {
        modifiers() {
            const { disabled, hasIcon, iconLeft, light, outline, small } = this
            return {
                'FlatButton--disabled': disabled,
                'FlatButton--hasIcon': hasIcon,
                'FlatButton--iconLeft': iconLeft,
                'FlatButton--outline': outline,
                'FlatButton--light': light,
                'FlatButton--small': small,
            }
        },
        hasIcon() {
            const { iconId } = this
            return typeof iconId === 'string'
        },
        toRoute() {
            return this.el === 'router-link' ? this.to : null
        }
    },
}
</script>

<style src="styles/components/buttons/FlatButton.styl" lang="stylus"></style>
