/* eslint-disable no-underscore-dangle */
/* eslint-disable no-console */

import { debounce } from 'lodash'

const promises = []
let isLateBinding = false;

global.__SSR_SAFE_TO_PRERENDER = false;
const waitForPromises = function waitForPromises() {
    const allPromises = promises.splice(0, promises.length)
    isLateBinding = true
    Promise.all(allPromises).then(() => {
        console.log('SSR snapshot starts now.')
        global.__SSR_SAFE_TO_PRERENDER = true
    })
}
const debouncedWait = debounce(waitForPromises, 100)

const SsrInterceptDirective = {
    bind(el, binding) {
        if (typeof binding.value === 'function') {
            if (!isLateBinding) {
                promises.push(binding.value())
                debouncedWait()
            }
        }
    },
}

export default SsrInterceptDirective;
