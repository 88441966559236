/* eslint-disable no-underscore-dangle */
import { debounce } from "lodash";

const listeners = {};

function bindScroll(id, callback) {
    const fn = debounce(() => {
        const { documentElement: doc } = document;
        const left = (window.pageXOffset || doc.scrollLeft) - (doc.clientLeft || 0);
        const top = (window.pageYOffset || doc.scrollTop) - (doc.clientTop || 0);
        callback({ left, top });
    }, 100);
    window.addEventListener("scroll", fn);
    listeners[id] = fn;
}

function unbindScroll(id) {
    const callback = listeners[id]
    if (callback) {
        delete listeners[id];
        window.removeEventListener('scroll', callback);
    }
}

const ScrollMonitorDirective = {
    bind(el, binding, vnode) {
        if (typeof binding.value === "function") {
            bindScroll(vnode.context._uid, binding.value);
        }
    },
    unbind(el, binding, vnode) {
        if (typeof binding.value === "function") {
            window.removeEventListener("scroll", binding.value);
            unbindScroll(vnode.context._uid);
        }
    }
};

export default ScrollMonitorDirective;
