function updateClasses(el, value) {
    const len = el.innerText.length;
    const rules = value.sort((a, b) => a.lengthGreaterThan - b.lengthGreaterThan);
    let classPick = '';
    for (let i = 0; i < rules.length; i++) {
        const { className, lengthGreaterThan } = rules[i];
        el.classList.remove(className);
        if (len > lengthGreaterThan) {
            classPick = className;
        } else {
            break;
        }
    }
    if (classPick !== '') {
        el.classList.add(classPick);
    }
}

const TextBreakpointsDirective = {
    bind(el, { value }) {
        updateClasses(el, value);
    },
}

export default TextBreakpointsDirective;
