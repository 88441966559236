<template>
    <section class="WrapperInfo">
        <drag-hint hide-on-mobile light/>
        <responsive-image
            class="WrapperInfo-Cover"
            :mobileSrc="data.cover.image_mobile"
            :desktopSrc="data.cover.image_desktop"
            background
        />
        <div class="WrapperInfo-Wrapper">
            <flat-button
                :to="{ name: 'about' }"
                el="router-link"
                class="WrapperInfo-BackButton"
                outline
            >
                Volver
            </flat-button>
            <h2 class="WrapperInfo-SectionName item-transition">
                <slot name="sectionName">
                    Acerca de
                </slot>
            </h2>
            <h1 class="WrapperInfo-Title item-transition">
                <slot name="title">
                    {{ data.content.title }}
                </slot>
            </h1>
            <div class="WrapperInfo-Content item-transition">
                <slot name="content">
                    <div v-html="data.content.body"></div>
                </slot>
            </div>
        </div>
    </section>
</template>

<script>
import inView from "in-view"
import ResponsiveImage from '@/app/components/ResponsiveImage.vue'
import { LAYOUT_SOCIAL_THEME } from '@/app/store/modules/app'
import DragHint from '@/app/components/DragHint.vue'
import FlatButton from '@/app/components/buttons/FlatButton.vue'

export default {
    name: 'WrapperInfo',
    components: {
        ResponsiveImage,
        FlatButton,
        DragHint,
    },
    props: {
        data: {
            type: Object,
            default: () => ({
                meta_tags: {},
                content: {},
                cover: {}
            }),
        },
    },
    mounted() {
        inView('.WrapperInfo .item-transition').on('enter', (el) => {
            el.classList.add("inview");
        });
        this.$store.dispatch(LAYOUT_SOCIAL_THEME, 'dark')
    }
}
</script>

<style src="styles/components/WrapperInfo.styl" lang="stylus"></style>
