<template>
    <generic-modal
        @closeClick="$emit('closeClick')"
        class="VideoGalleryModal"
    >
        <ul class="VideoGalleryModal-CurrentVideo-List">
            <li
                class="VideoGalleryModal-CurrentVideo-Item"
                v-for="(item, index) in items"
                :key="index"
            >
                <video-player
                    ref="selectedVideo"
                    v-if="index === playingIndex"
                    :playsinline="true"
                    :options="{
                        controls: false,
                        autoplay: true,
                        sources: [{
                            type: 'video/mp4',
                            src: item.src,
                        }],
                    }"
                />
            </li>
        </ul>
        <div class="VideoGalleryModal-NavWrapper">
            <carousel-progress-info
                class="VideoGalleryModal-Progress"
                ref="progressBar"
                :total="slidesCount"
                light
            />
            <carousel-navigator
                class="VideoGalleryModal-Navigator"
                :progressLabel="false"
                @next="onNextClick"
                @prev="onPrevClick"
            />
        </div>
        <carousel
            class="VideoGalleryModal-CarouselPreview"
            ref="carouselPreview"
            :options="carouselPreviewOptions"
            @initialized="updateNavigator"
            @change="updateNavigator"
        >
            <carousel-slide
                class="VideoGalleryModal-PreviewSlide"
                v-for="(item, index) in items"
                :key="index"
                @click.native="onPreviewClick(index)"
            >
                <video-player
                    ref="previewVideos"
                    :playsinline="true"
                    :options="{
                        fluid: true,
                        controls: false,
                        autoplay: false,
                        sources: [{
                            type: 'video/mp4',
                            src: item.src,
                        }],

                    }"
                />
            </carousel-slide>
        </carousel>
    </generic-modal>
</template>

<script>
import GenericModal from '@/app/components/modals/GenericModal.vue'
import 'video.js/dist/video-js.css'
import { videoPlayer } from 'vue-video-player'
import Carousel from '@/app/components/carousel/Carousel.vue'
import CarouselSlide from '@/app/components/carousel/CarouselSlide.vue'
import CarouselNavigator from '@/app/components/carousel/CarouselNavigator.vue'
import CarouselProgressInfo from '@/app/components/carousel/CarouselProgressInfo.vue'

export default {
    name: 'VideoGalleryModal',
    data() {
        return {
            slidesCount: 0,
            slidesIndex: 0,
            slidesProgress: 0,
            playingIndex: 0,
            temp: 0,
            carouselPreviewOptions: {
                slidesPerView: 4,
                spaceBetween: 20,
                breakpoints: {
                    1024: {
                        slidesPerView: 3,
                    },
                    980: {
                        slidesPerView: 2,
                    }
                }
            }
        }
    },
    components: {
        GenericModal,
        videoPlayer,
        CarouselNavigator,
        CarouselProgressInfo,
        CarouselSlide,
        Carousel,
    },
    props: {
        items: {
            type: Array,
            default: () => [],
        },
    },
    methods: {
        onPreviewClick(index) {
            this.playingIndex = index
        },
        onNextClick() {
            const { carouselPreview } = this.$refs
            carouselPreview.nextSlide()
        },
        onPrevClick() {
            const { carouselPreview } = this.$refs
            carouselPreview.prevSlide()
        },
        updateNavigator() {
            const { carouselPreview, progressBar } = this.$refs
            //this.slidesIndex = carouselPreview.index
            this.slidesCount = carouselPreview.count
            /*
                Nasty workarond to avoid re-updating vue components
                inside the carousel slides (video player component).
                I suspect that some unexpected reactive watcher
                is doing a loop somehow when using proper dynamic
                props binding from the component.
                I should't be using video components for thumbnail previews
                anyway's. So this should be only a temporal fix.
            */
            progressBar.progress = carouselPreview.progress
        },
    },
}
</script>

<style src="styles/components/modals/VideoGalleryModal.styl" lang="stylus"></style>
