/* eslint-disable no-underscore-dangle */
import axios from 'axios'
import qs from 'qs'
import { environment } from '@/config'

export default class Api {

    _endpoints = Object.freeze({
        pages: `${environment.url.api}/pages`,
        pilars: `${environment.url.api}/pilars`,
        complexes: `${environment.url.api}/complexes`,
        sections: `${environment.url.api}/sections`,
        prevWorks: `${environment.url.api}/old_complexes_all`,
        contact: `${environment.url.api}/contact`,
        homeswitch: `${environment.url.api}/switch`,
    })

    get endpoints() {
        return this._endpoints
    }

    async getPage(permalink) {
        const pages = await this.getPages()
        return pages.find(page => page.perma_link === permalink)
    }

    async getPilars() {
        const { pilars } = this._endpoints
        const res = await Api.get(pilars)
        return res.data.response
    }

    async getHomeSwtich() {
        const { homeswitch } = this._endpoints
        const res = await Api.get(homeswitch)
        return res;
    }

    async getComplexes() {
        const { complexes } = this._endpoints
        const res = await Api.get(complexes)
        return res.data.response
    }

    async getSections() {
        const { sections } = this._endpoints
        const res = await Api.get(sections)
        return res.data.response
    }

    async getPages() {
        const { pages } = this._endpoints
        const res = await Api.get(pages)
        return res.data.response
    }

    async getPreviousWorks() {
        const { prevWorks } = this._endpoints
        const res = await Api.get(prevWorks)
        return res.data.response
    }

    async postContact(formData) {
        const { contact } = this._endpoints
        const res = await Api.post(contact, formData)
        return res
    }

    /**
     * @param {string} endpoint
     * @param {{}=} data
     */
    static async post(endpoint, data) {
        const response = await axios
            .post(endpoint, qs.stringify(data))
        if (response.status === 200) {
            return response
        }
        throw response

    }

    /**
     * @param {string} endpoint
     */
    static async get(endpoint) {
        const response = await axios.get(endpoint)
        if (response.status === 200) {
            return response
        }
        throw response

    }
}
