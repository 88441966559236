<template>
    <section class="WrapperGrid">
        <header class="WrapperGrid-Header">
            <responsive-image
                class="WrapperGrid-Cover"
                :mobileSrc="data.background.image_mobile"
                :desktopSrc="data.background.image_desktop"
                background
            >
                <h1 class="WrapperGrid-SectionTitle">
                    <slot name="title">
                        {{ data.content.title }}
                    </slot>
                </h1>
            </responsive-image>
            <nav class="WrapperGrid-SectionNav">
                <ul
                    class="WrapperGrid-TagList"
                    v-if="showTagsFilter"
                >
                    <li
                        class="WrapperGrid-TagItem"
                        :class="getTagItemModifiers(tag)"
                        v-for="(tag, index) in data.tags"
                        :key="index"
                        @click="currentTag = tag"
                    >
                        {{ tag }}
                    </li>
                </ul>
                <flat-button
                    class="WrapperGrid-BackButton"
                    el="router-link"
                    :to="{ name: 'home' }"
                >
                    Atras
                </flat-button>
            </nav>
        </header>
        <ul class="WrapperGrid-ItemList">
            <li class="WrapperGrid-Item">
                <router-link
                    class="WrapperGrid-ItemLink"
                    :to="item.path ? item.path : {}"
                    v-for="(item, index) in filteredItems"
                    :key="index"
                >
                <div class="WrapperGrid-ItemContent">
                    <div class="WrapperGrid-Tag"> {{ item.tag }} </div>
                    <h2 class="WrapperGrid-ItemTitle">{{ item.title }}</h2>
                    <div v-html="item.description"></div>
                </div>
                <responsive-image
                    class="WrapperGrid-Image"
                    :mobileSrc="item.thumb.image_mobile"
                    :desktopSrc="item.thumb.image_desktop"
                    background
                />
                </router-link>
            </li>
        </ul>
    </section>
</template>

<script>
import ResponsiveImage from '@/app/components/ResponsiveImage.vue'
import FlatButton from '@/app/components/buttons/FlatButton.vue'
import { LAYOUT_SOCIAL_THEME } from '@/app/store/modules/app'

export default {
    name: 'WrapperGrid',
    components: {
        ResponsiveImage,
        FlatButton,
    },
    data() {
        return {
            currentTag: null,
        }
    },
    props: {
        data: {
            type: Object,
            default: () => ({
                items: [],
                tags: [],
            }),
        },
    },
    mounted() {
        this.selectFirstTag()
        this.$store.dispatch(LAYOUT_SOCIAL_THEME, 'dark')
    },
    methods: {
        selectFirstTag() {
            const { data, showTagsFilter } = this
            if (showTagsFilter) {
                // eslint-disable-next-line
                this.currentTag = data.tags[0]
            }
        },
        getTagItemModifiers(item) {
            const { currentTag } = this
            return {
                'WrapperGrid-TagItem--active': item === currentTag
            }
        },
    },
    computed: {
        filteredItems() {
            const { data, currentTag, showTagsFilter } = this
            if (showTagsFilter && currentTag) {
                return data.items
                    .filter(item => item.tag === currentTag)
            }
            return data.items
        },
        showTagsFilter() {
            const { $mq, data } = this
            const { tags } = data
            const isDesktop = $mq === 'desktop'
            const hasTags = Array.isArray(tags) && tags.length > 0
            return isDesktop && hasTags
        }
    },
}
</script>

<style src="styles/components/WrapperGrid.styl" lang="stylus"></style>
