<template>
    <div class="FormInputText" :class="modifiers">
        <label :for="id">
            {{ label }}
            <input
                class="FormInputText-Input"
                :placeholder="placeholder"
                ref="input"
                :id="id"
                :name="id"
                :type="type"
                :value="value"
                :maxlength="maxlength"
                @input="$emit('input', $event.target.value)"
            >
        </label>
        <div class="FormInputText-Error">{{ error }}</div>
    </div>
</template>

<script>
import { uniqueId } from 'lodash'

export default {
    name: 'FormInputText',
    $_veeValidate: {
        name() {
            return this.label.replace('*', '').trim()
        },
        value() {
            // Return the input value to avoid sync issues with vee validate
            // confirmation rule: https://bit.ly/2RgRctd
            return this.$refs.input.value
        },
    },
    props: {
        value: String,
        error: String,
        id: {
            type: String,
            default: () => uniqueId('form-input-text-'),
        },
        type: {
            type: String,
            default: 'text',
            validator: (val) => {
                const validValues = [
                    'url',
                    'text',
                    'password',
                    'email',
                    'search'
                ]
                return validValues.indexOf(val) !== -1
            }
        },
        label: {
            type: String,
            required: true,
        },
        placeholder: {
            type: String,
            default: '',
        },
        disabled: {
            type: Boolean,
            default: false,
        },
        maxlength: {
            type: String,
            default: ''
        }
    },
    computed: {
        modifiers() {
            const { disabled, error } = this
            return {
                'FormInputText--disabled': disabled,
                'FormInputText--error': !!error,
            }
        },
    },
}
</script>

<style src="styles/components/form/FormInputText.styl" lang="stylus"></style>
