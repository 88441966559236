<template>
    <div class="GenericModal">
        
        <div :class=" isHome ? 'modal_popup GenericModal-Content':'GenericModal-Content'">
            <div class="GenericModal-ButtonBar">
                <div
                    @click="onCloseClick"
                    class="GenericModal-CloseButton"
                >
                    <svg><use xlink:href="#icon-cross"/></svg>
                </div>
            </div>
            <slot></slot>
        </div>
    </div>
</template>

<script>

export default {
    name: 'GenericModal',
    data() {
        return {
            isHome: false
        }
    },
    props: {
        mobileSrc: {
            type: String,
            default: '',
        },
        desktopSrc: {
            type: String,
            default: '',
        },
    },
    methods: {
        onCloseClick() {
            this.$emit('closeClick')
        }
    },
    mounted(){
        const {$mq, $route} = this
        this.isHome = ($route.name === 'home') ? true :  false;
        //console.log('Generic isHome',this.isHome);
    }
}
</script>

<style src="styles/components/modals/GenericModal.styl" lang="stylus"></style>
<style scoped>
.modal_popup{
    max-width: 500px;
    max-height: 500px;
}
</style>
