<template>
    <div class="ContentsPage">
        <wrapper-info :data="section"/>
        <div class="form">
            <form id="job-form-container" action="" @submit="checkForm">
                <fieldset class="field">
                    <input type="text" id="name-field" placeholder="*Nombre" required name="name" @change="checkValidations">
                </fieldset>
                <fieldset class="field">
                    <input type="text" id="last_name-field" placeholder="*Apellido" required name="last_name" @change="checkValidations">
                </fieldset>
                <fieldset class="field">
                    <input type="number" id="age-field" placeholder="*Edad (Años)" required name="age" @change="checkValidations" maxlength="2">
                </fieldset>
                <fieldset class="field">
                    <input type="email" id="mail-field" placeholder="*Correo" required name="email" @change="checkValidations">
                </fieldset>
                <fieldset class="field full-width">
                    <textarea name="message" id="message-field" cols="30" rows="10" required placeholder="*Mensaje" @change="checkValidations"></textarea>
                </fieldset>
                <fieldset class="field">
                    <label for="cv-field"><span id="file-message">*Adjuntar CV (Máximo 2mb)</span><input type="file" ref="cvFile" id="cv-field" accept=".pdf,.doc,.docx,.jpg,.jpeg" name="file" required @change="checkSize"><p>Tu archivo debe pesar menos de 2mb</p></label>
                </fieldset>
                <fieldset class="field">
                    <select id="areas-field" required name="interest" @change="checkValidations">
                        <option selected disabled value="Áreas de interés">*Áreas de interés</option>
                        <option value="Administración y Finanzas">Administración y Finanzas</option>
                        <option value="Recursos Humanos">Recursos Humanos</option>
                        <option value="Contabilidad">Contabilidad</option>
                        <option value="Procesos">Procesos</option>
                        <option value="Gobierno Corporativo">Gobierno Corporativo</option>
                        <option value="Sistemas">Sistemas</option>
                        <option value="Marketing">Marketing</option>
                        <option value="Comercial">Comercial</option>
                        <option value="Fiscal">Fiscal</option>
                        <option value="Ventas">Ventas</option>
                        <option value="Tesorería">Tesorería</option>
                        <option value="Proyectos">Proyectos</option>
                        <option value="Cuentas por cobrar">Cuentas por cobrar</option>
                        <option value="Legal">Legal</option>
                    </select>
                </fieldset>
                <div class="field">
                    <p>*Todos los campos son obligatorios.</p>
                </div>
                <fieldset class="field actions">
                    <button type="submit" id="submit-form-btn" :disabled="isValid == false">Enviar</button>
                </fieldset>
            </form>
        </div>
    </div>
</template>
<script>
import axios from 'axios'
import { environment } from '../../config/index'
import SectionPage from '@/app/mixins/SectionPage'
import WrapperInfo from '@/app/components/WrapperInfo.vue'
import MixHead from '@/app/mixins/MixHead'

export default {
    name: 'ContentsPage',
    submited: false,
    components: {
        WrapperInfo,
    },
    data: () => {
        return {
            isValid: false
        }
    },
    mixins: [
        SectionPage,
        MixHead,
    ],
    methods: {
        checkValidations() {
            this.isValid = (window["name-field"].value && window["last_name-field"].value && window["age-field"].value && window["mail-field"].value && window["message-field"].value && window["cv-field"].value && window["areas-field"].value);
        },
        checkForm (e) {
            e.preventDefault();
            window["submit-form-btn"].setAttribute("disabled", "disabled");
            axios.post(`${environment.url.api}/job`, new FormData(window["job-form-container"]), { headers: { 'Content-Type': 'multipart/form-data' } }).then(() => {
                TweenMax.to("#job-form-container", 0.3, {
                    opacity: 0,
                    onComplete: () => {
                        window["job-form-container"].innerHTML = "<h3>Gracias por enviarnos tus datos. ¡Pronto estaremos en contacto contigo!</h3>";
                        TweenMax.to("#job-form-container", 0.3, {
                            opacity: 1
                        });
                    }
                })
            });
        },
        checkSize() {
            const file = this.$refs.cvFile.files[0];
            if (typeof file !== 'undefined') {
                const maxSize = 2048;
                const size = (file.size / 1024);
                if (size > maxSize) {
                    TweenMax.set("label p", {
                        display: "block"
                    });
                    window["file-message"].innerText = "Adjuntar CV (Máximo 2mb)";
                    this.$refs.cvFile.value = "";
                } else {
                    TweenMax.set("label p", {
                        display: "none"
                    });
                    window["file-message"].innerText = `Archivo adjunto: ${file.name}`;
                }
            } else {
                window["file-message"].innerText = "Adjuntar CV (Máximo 2mb)";
            }
            this.checkValidations()
        }
    },
    computed: {
        metas() {
            const { section } = this
            return section.meta_tags
        }
    }
}
</script>
<style src="styles/views/JobsPage.styl" lang="stylus"></style>
