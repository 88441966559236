export default {
    data() {
        return {
            TextUtils: {
                breakpoints: {
                    h1: [
                        { lengthGreaterThan: 0, className: "h1" },
                        { lengthGreaterThan: 100, className: "h1--small" },
                    ],
                    h2: [
                        { lengthGreaterThan: 0, className: "h2" },
                        { lengthGreaterThan: 11, className: "h2--medium" },
                        { lengthGreaterThan: 16, className: "h2--small" },
                    ],
                },
            }
        }
    },
}
