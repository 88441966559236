<template>
    <button class="HomeButton">
        <img src="" />
    </button>
</template>
<script>
export default {
    name: 'HomeButton',
    props: {
    },
    computed: {
    },
}
</script>

<style src="styles/components/buttons/HomeButton.styl" lang="stylus"></style>
