export default class Canvas {
    constructor(_selector, args) {
        if (args===undefined) args = {};
        // sizes
        this.pxRatio = window.devicePixelRatio;
        this.width = args.width || window.innerWidth;
        this.height = args.height || window.innerHeight;
        //
        this.canvas = document.getElementById(_selector);
        this.context = this.canvas.getContext("2d");
        //
        window.addEventListener("resize", this.onResize.bind(this), false);
        this.onResize();
    }

    setSize(_width, _height) {
        if (_width === undefined) _width = this.width;
        if (_height === undefined) _height = this.height;
        this.canvas.style.width = _width + "px";
        this.canvas.style.height = _height + "px";
        this.canvas.width = _width * this.pxRatio;
        this.canvas.height = _height * this.pxRatio;
        this.width = _width;
        this.height = _height;
        this.center = {
            x: this.canvas.width / 2,
            y: this.canvas.height / 2
        }
    }

    onResize() {
        this.setSize(this.width, this.height);
    }

    clear() {
        this.context.clearRect(0, 0, this.canvas.width, this.canvas.height);
    }

    background(_fill) {
        if (_fill === undefined) return "El color de background es requerido";
        this.context.fillStyle = _fill;
        this.context.fillRect(0, 0, this.canvas.width, this.canvas.height);
    }
}