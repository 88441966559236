<template>
    <section class="Sustainability">
        <drag-hint hide-on-mobile light />
        <section-sustainability-block align="left" :data="blocks[0]">
            <!-- <template #btn>
                <a
                    href="https://begrand.mx/BG_050224_129%20AP%20declaratoria%20ASG%20BG_4SEP.pdf"
                    download
                    class="pdf-btn"
                >
                    <button>Declaratoria Ambiental</button>
                </a>
            </template> -->
        </section-sustainability-block>
        <section-sustainability-block align="right" :data="blocks[1]" />
        <section-sustainability-block align="left" :data="blocks[2]">
            <!--water-saving-widget
                :align="waterWidgetAlign"
                :windowVisible="widgetOpen"
                @open="widgetOpen = true"
                @close="widgetOpen = false"
                class="Sustainability-SavingsWidget"
            /-->
        </section-sustainability-block>
        <section-sustainability-block align="right" :data="blocks[3]" />
        <section-sustainability-block align="left" :data="blocks[4]" />
        <section-sustainability-block align="right" :data="blocks[5]" />
        <view-nav :items="sections" :item-active="activeSection" light />
    </section>
</template>

<script>
import inView from "in-view";
import { mapState } from "vuex";
import { LAYOUT_SOCIAL_THEME } from "@/app/store/modules/app";
import ViewNav from "@/app/components/navs/ViewNav.vue";
import MixHomeSections from "@/app/mixins/MixHomeSections";
import MixHead from "@/app/mixins/MixHead";
import SectionSustainabilityBlock from "@/app/components/sections/SectionSustainabilityBlock.vue";
//import WaterSavingWidget from '@/app/components/WaterSavingWidget.vue'
import DragHint from "@/app/components/DragHint.vue";

export default {
    name: "Sustainability",
    mixins: [MixHead, MixHomeSections],
    components: {
        SectionSustainabilityBlock,
        //WaterSavingWidget,
        ViewNav,
        DragHint
    },
    data() {
        return {
            activeSection: "sustainability",
            widgetOpen: false
        };
    },
    mounted() {
        const { $mq } = this;
        if ($mq === "desktop") {
            this.widgetOpen = true;
        }
        inView(".Sustainability .item-transition").on("enter", (el) => {
            el.classList.add("inview");
        });
        this.$store.dispatch(LAYOUT_SOCIAL_THEME, "light");
        this.readyToRender();
    },
    methods: {
        getPageByPermalink(permalink) {
            return this.pages.find(page => page.permalink === permalink);
        }
    },
    computed: {
        ...mapState({
            pages: state => state.app.contents.pages
        }),
        blocks() {
            return this.getPageByPermalink("/sustentabilidad").blocks;
        },
        /*
        waterWidgetAlign() {
            const { $mq } = this
            return $mq === 'desktop' ? 'right' : 'left'
        },
        */
        metas() {
            const page = this.getPageByPermalink("/sustentabilidad");
            return page.meta_tags;
        }
    }
};
</script>

<style src="styles/views/Sustainability.styl" lang="stylus"></style>
