import LocaleManager from "@/foo/core/locale/LocaleManager";

const VueFoo = {};

VueFoo.install = function (Vue) {
    // LOCALE
    Vue.prototype.$setLocale = LocaleManager.loadLocale;
    Vue.setLocale = LocaleManager.loadLocale;
};

export default VueFoo;
