<template>
    <generic-section-hero
        v-bind="$attrs"
        class="SectionComplexHero"
        :showDragHint="true"
    >
        <template v-slot="{ data, slide, index, state, playVideo }">
            <div class="SectionComplexHero-ContentWrapper">
                <h1>
                    <div class="SectionComplexHero-Subtitle" v-if="slide.content.title !== 'Be Roma'" :style="`color:${slide.content.colorname};opacity: 1 !important;`" >Be Grand</div>
                    <div class="SectionComplexHero-Subtitle" v-else :style="`color:${slide.content.colorname};opacity: 1 !important;`" >Be</div>
                    <div class="SectionComplexHero-Title" v-if="slide.content.title !== 'Be Roma'" :data-color="slide.content.colorname">{{ slide.content.title }}</div>
                    <div class="SectionComplexHero-Title" v-else :data-color="slide.content.colorname">Roma</div>
                </h1>
            </div>
        </template>
    </generic-section-hero>
</template>

<script>
import GenericSectionHero from '@/app/components/sections/GenericSectionHero.vue'

export default {
    name: 'SectionComplexHero',
    components: {
        GenericSectionHero,
    },
}
</script>

<style src="styles/components/sections/SectionComplexHero.styl" lang="stylus"></style>
