<template>
    <section class="ScheduleThankYou" v-viewport-size:debounce.100='SectionUtils.viewport'>
        <responsive-image
            class="ScheduleThankYou-Image"
            :mobileSrc="background.image_mobile"
            :desktopSrc="background.image_desktop"
            background
        >
        </responsive-image>
        <div class="ScheduleThankYou-Content">
            <p>
                <strong>Tus datos han sido enviados.</strong>
            </p>
            <p>
                Gracias por ponerte en contacto con nosotros.<br>
                Pronto nos estaremos comunicando contigo.
            </p>
            <flat-button
                class="ScheduleThankYou-PrevButton"
                iconId="icon-arrow-left"
                iconLeft
                el="router-link"
                :to="backUrl"
            >
                Regresar
            </flat-button>
        </div>
    </section>
</template>

<script>
import FlatButton from '@/app/components/buttons/FlatButton.vue'
import ResponsiveImage from '@/app/components/ResponsiveImage.vue'
import SectionUtils from '@/app/mixins/SectionUtils'
import ComplexUtils from '@/app/mixins/ComplexUtils'

export default {
    name: 'ScheduleThankYou',
    components: {
        FlatButton,
        ResponsiveImage,
    },
    mixins: [
        SectionUtils,
        ComplexUtils,
    ],
    computed: {
        background() {
            const { $route, complex, complexes } = this
            return this.complex.schedule.background
        },
        backUrl() {
            const { params } = this.$route
            return {
                name: 'complex',
                params,
            }
        }
    }
}
</script>

<style src="styles/views/ScheduleThankYou.styl" lang="stylus"></style>
