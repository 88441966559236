<template>
    <div
        class="ContentsPage"
    >
        <wrapper-info
            :data="section"
        />
    </div>
</template>

<script>
import SectionPage from '@/app/mixins/SectionPage'
import WrapperInfo from '@/app/components/WrapperInfo.vue'
import MixHead from '@/app/mixins/MixHead'

export default {
    name: 'ContentsPage',
    components: {
        WrapperInfo,
    },
    mixins: [
        SectionPage,
        MixHead,
    ],
    computed: {
        metas() {
            const { section } = this
            return section.meta_tags
        }
    }
}
</script>

<style src="styles/views/ContentsPage.styl" lang="stylus"></style>
