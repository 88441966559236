<template>
    <component
        class="ResponsiveImage"
        :is="elementType"
        :class="getClassModifiers"
        :style="getStyleModifiers"
    >
        <source v-if="!background" :srcset="desktopSrc" media="(min-width: 760px)">
        <img v-if="!background" :src="mobileSrc || desktopSrc">
        <slot v-if="background"></slot>
    </component>
</template>

<script>
export default {
    name: 'ResponsiveImage',
    props: {
        mobileSrc: {
            type: String,
            default: '',
        },
        desktopSrc: {
            type: String,
            default: '',
        },
        background: {
            type: Boolean,
            default: false,
        },
        contain: {
            type: Boolean,
            default: false,
        }
    },
    computed: {
        elementType() {
            return this.background ? 'div' : 'picture';
        },
        getClassModifiers() {
            return {
                'ResponsiveImage--background': this.background,
                'ResponsiveImage--contain': this.contain,
            }
        },
        getStyleModifiers() {
            if (this.background) {
                const { $mq, desktopSrc, mobileSrc } = this
                let mobileSrcFallback = mobileSrc
                if (mobileSrc === '/') {
                    mobileSrcFallback = desktopSrc
                }
                let src = $mq === 'desktop' ? desktopSrc : mobileSrcFallback
                src = src || desktopSrc || mobileSrcFallback;

                return {
                    backgroundImage: `url("${src}")`
                }
            }
            return {}
        },
    }
}
</script>

<style src="styles/components/ResponsiveImage.styl" lang="stylus"></style>
