<template>
    <nav class="LayoutSectionMenu">
        <div class="LayoutSectionMenu-container">
            <!--div class="LayoutSectionMenu-header">
                <template v-for="index in MixCrsl.numberOfSlides">
                    <span
                        class="LayoutSectionMenu-indicator"
                        :key="`indicator-${index}`"
                        :class="activeModifier(index)"
                        @click="MixCrslGoto(index)"
                    >
                        {{ index | zeroPad }}
                    </span>
                    <template
                        v-if="index !== MixCrsl.numberOfSlides"
                    >
                        /
                    </template>
                </template>
            </div-->
            <div class="LayoutSectionMenu-main" :style="mainStyle">
                <template v-for="index in MixCrsl.numberOfSlides">
                    <menu-pane
                        v-show="MixCrslIsActive(index)"
                        :key="`menu-pane-${index}`"
                        :items="index === 1 ? items : complexes"
                        :anim-direction="MixCrsl.direction"
                        :showExtra="index === 1 ? false : true"
                        :showExtraSp="index === 1 ? false : true"
                        :showExtraSpComplex="index === 1 ? false : true"
                        @height-update="updateMainHeight"
                        @item-click="$emit('close')"
                    />
                </template>
            </div>
            <!--
            <div class="LayoutSectionMenu-bottomNav">
                <div
                    role="button"
                    class="LayoutSectionMenu-btn LayoutSectionMenu-btn--back"
                    :class="backModifiers"
                    @click="MixCrslBack"
                ></div>
                <div
                    role="button"
                    class="LayoutSectionMenu-btn LayoutSectionMenu-btn--next"
                    :class="nextModifiers"
                    @click="MixCrslNext"
                ></div>
            </div>
            -->
            <div class="LayoutSectionMenu-sideNav">
                <span
                    class="LayoutSectionMenu-label"
                    :class="activeModifier(1)"
                    @click="MixCrslGoto(1)"
                >
                    Be Grand
                </span> /
                <span
                    class="LayoutSectionMenu-label"
                    :class="activeModifier(2)"
                    @click="MixCrslGoto(2)"
                >
                    Residencial
                </span>
            </div>
        </div>
        <div
            role="button"
            class="LayoutSectionMenu-close"
            @click="$emit('close')"
        >
            Cerrar
        </div>
    </nav>
</template>

<script>
import MixCrsl from '@/app/mixins/MixCrsl'
import MenuPane from '@/app/components/MenuPane.vue'

export default {
    name: 'LayoutSectionMenu',
    props: {
        items: {
            type: Array,
            required: true,
        },
        complexes: {
            type: Array,
            required: true,
        },
    },
    mixins: [
        MixCrsl,
    ],
    components: {
        MenuPane,
    },
    data() {
        return {
            mainHeight: 0,
        }
    },
    mounted() {
        this.initMixCrsl()
    },
    methods: {
        initMixCrsl() {
            const { MixCrsl } = this
            MixCrsl.activeSlide = 1
            MixCrsl.numberOfSlides = 1
            MixCrsl.infinite = false
        },
        activeModifier(index) {
            return {
                'is-active': this.MixCrslIsActive(index),
            }
        },
        updateMainHeight(e) {
            this.mainHeight = e.height
        },
    },
    computed: {
        mainStyle() {
            const { mainHeight } = this
            return {
                height: `${mainHeight}px`,
            }
        },
        backModifiers() {
            const { MixCrslBackDisabled } = this
            return {
                'is-disabled': MixCrslBackDisabled,
            }
        },
        nextModifiers() {
            const { MixCrslNextDisabled } = this
            return {
                'is-disabled': MixCrslNextDisabled,
            }
        },
    },
}
</script>

<style src="styles/components/layout/LayoutSectionMenu.styl" lang="stylus"></style>
