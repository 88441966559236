<template>
    <div
        class="LayoutSocial"
        :class="classModifiers"
        v-scroll-monitor="onScroll"
    >
        <social-list class="LayoutSocial-List"/>
    </div>
</template>

<script>
import SocialList from '@/app/components/SocialList.vue'
import { mapState } from 'vuex'

export default {
    name: 'LayoutSocial',
    components: {
        SocialList,
    },
    data() {
        return {
            scrollTop: 0,
        }
    },
    methods: {
        onScroll(value) {
            this.scrollTop = value.top;
        },
    },
    computed: {
        classModifiers() {
            const { theme, scrollTop } = this
            return {
                [`LayoutSocial--${theme}`]: true,
                [`LayoutSocial--solid-${theme}`]: scrollTop > 10,
            }
        },
        ...mapState({
            theme: state => state.app.layout.social_theme,
        })
    }
}
</script>

<style src="styles/components/layout/LayoutSocial.styl" lang="stylus"></style>
