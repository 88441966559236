import Canvas from "../modules/canvas";

class PreloaderCanvas extends Canvas {
    constructor(containerId) {
        super(containerId);
        // this.colors = ["#ea4335", "#fbbc05", "#2da54d", "#4285f4"];
        this.colors = ["#FFF"];
        this.rects = [];
        this.setRects();
        this.setTl();
        this.loading = false;
        this.loaded = false;
        this.hide();
    }

    setRects() {
        this.rects = [];
        for (var i = 0; i < this.colors.length; i++) {
            let _color = this.colors[i];
            let _rect = {
                color: _color,
                width: 0,
                x: 0
            }
            this.rects.push(_rect);
        }
    }

    showText() {
        let els = document.getElementsByClassName("SectionComplexHero-Title");
        if (!els[0]) return false;
        let html = "";
        let words = els[0].innerText.split(" ");
        let color = els[0].dataset.color;
        els[0].innerHTML = "";
        for (var i = 0; i < words.length; i++) {
            html += "<span style='color:"+color+";'>" + words[i] + "</span> ";
        }
        els[0].innerHTML = html;
        TweenMax.staggerTo([".SectionComplexHero-Subtitle", els[0].children], 0.45, {
            ease: Power2.easeOut,
            delay: 0.5,
            opacity: 1,
            x: 0
        }, 0.1);
        TweenMax.to(".SectionComplexHero", 0.3, {
            opacity: 1,
            ease: Power2.easeOut,
            delay: 0.15,
            scaleX: 1,
            scaleY: 1
        });
    }

    hide() {
        return this.tl.play();
    }

    show() {
        if (this.loading) return false;
        this.loading = true;
        TweenMax.to(".SectionComplexHero", 0.3, {
            ease: Power2.easeOut,
            scaleX: 1.2,
            scaleY: 1.2,
            opacity: 0
        });
        this.fromRandom();
    }

    fromRandom() {
        let _random = Math.floor(Math.random() * 3);
        let _x = 0;
        let _y = 0;
        switch (_random) {
            case 0:
                _y = -this.canvas.height;
                break;
            case 1:
                _x = -this.canvas.width;
                break;
            case 2:
                _y = this.canvas.height;
                break;
            default:
                _x = -this.canvas.width;
                break;
        }
        window["preloader"].removeAttribute("style");
        window["preloader"].classList.remove("loaded");
        TweenMax.from("#preloader", 0.45, {
            ease: Power2.easeOut,
            x: _x,
            y: _y,
            onComplete: () => {
                this.tl.duration(2).play(0);
            }
        });
    }

    setTl(_callback) {
        this.setRects();
        this.tl = new TimelineMax({
            delay: 0.45,
            onUpdate: this.render.bind(this),
            onStart: () => {
                window["preloader"].classList.add("started");
            },
            onComplete: () => {
                this.loaded = true;
                this.loading = false;
                this.showText();
                if (_callback) _callback();
            }
        });
        this.tl.staggerTo(this.rects, 1, {
            ease: Power3.easeInOut,
            width: this.canvas.width,
            onComplete: () => {
                window["preloader"].classList.add("loaded");
            }
        }, 0.1).staggerTo(this.rects, 1, {
            ease: Power3.easeInOut,
            x: this.canvas.width,
            onComplete: () => {
                this.showText();
                window["preloader"].style.display = "none";
            }
        }, 0.15, 0.5);
        this.tl.pause();
    }

    rectIn(_rect, i) {
        TweenLite.to(_rect, 1, {
            ease: Power2.easeInOut,
            width: this.canvas.width,
            delay: i * 0.1,
            onComplete: () => {
                TweenLite.to(_rect, 0.6, {
                    ease: Power2.easeInOut,
                    x: this.canvas.width
                });
            }
        });
    }

    render() {
        this.clear();
        for (var i = 0; i < this.rects.length; i++) {
            let _rect = this.rects[i];
            this.context.save();
            this.context.fillStyle = _rect.color;
            this.context.fillRect(_rect.x, 0, _rect.width, this.canvas.height);
            this.context.restore();
        }
    }
}

export default PreloaderCanvas;