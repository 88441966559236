<template>
    <div class="DatePicker">
        <label for="date-picker">Selecciona una Fecha</label>
        <flat-pickr
            ref="datePicker"
            name="date-picker"
            v-model="date"
            :config='config'
        />
    </div>
</template>

<script>
import flatPickr from 'vue-flatpickr-component'
import { Spanish } from 'flatpickr/dist/l10n/es'

export default {
    name: 'DatePicker',
    components: {
        flatPickr,
    },
    mounted() {
        const { datePicker } = this.$refs
        datePicker.fp.setDate(new Date(), true, 'Y-m-d')
    },
    data() {
        return {
            date: new Date(),
            config: {
                inline: true,
                altInput: true,
                altInputClass: 'DatePicker-Input',
                dateFormat: 'Y-m-d',
                minDate: "today",
                defaultDate: 'today',
                locale: Spanish,
            }
        }
    },
    methods: {
        formatSelectedDate(format) {
            const { datePicker } = this.$refs
            const date = datePicker.fp.selectedDates[0]
            return datePicker.fp.formatDate(date, format)
        }
    }
}
</script>

<style src="styles/components/DatePicker.styl" lang="stylus"></style>
