<template>
    <section
        class="Home"
        v-ssr-intercept="onSsrIntercept"
    >
        <section-home-hero :data="home.hero" :items="test" />
        <home-modal
            v-if="showModalhome"
            :items="dataModal"
            @closeClick="onModalCloseClick"
        />
        <home-button v-if="hswitch != 0" @click.native="onHomeModalClick" />
        <view-nav
            :items="sections"
            :item-active="activeSection"
            light
        />
    </section>
</template>

<script>

import { mapState } from 'vuex'
import { LAYOUT_SOCIAL_THEME } from '@/app/store/modules/app'
import Api from '@/app/utils/Api'
import SectionHomeHero from '@/app/components/sections/SectionHomeHero.vue'
import HomeButton from '@/app/components/buttons/HomeButton.vue'
import ViewNav from '@/app/components/navs/ViewNav.vue'
import HomeModal from '@/app/components/modals/HomeModal.vue'
import MixHomeSections from '@/app/mixins/MixHomeSections'
import MixHead from '@/app/mixins/MixHead'

export default {
    name: 'Home',
    mixins: [
        MixHead,
        MixHomeSections,
    ],
    data() {
        return {
            hswitch: 0,
            showModalhome: true,
            activeSection: 'home',
            dataModal: {
                title: '',
                description: '',
                image: '',
            },
	    test: ['xx', 'yy']
        }
    },
    async created() {
        const homePage = await Api.getPage('/');
        const homeSwitch = await Api.getHomeSwtich();
        console.log("homeSwitch: ", homeSwitch);
        console.log('this', this)
        const {$mq } = this;
        console.log($mq);
        if ($mq === "desktop"){
            this.dataModal.image = homePage.popup_img;
        } else if($mq === "mobile"){
            this.dataModal.image = homePage.popup_img_mobile;
        }
        this.dataModal.title = homePage.popup_title;
        this.dataModal.description = homePage.popup_description;
        this.hswitch = homeSwitch.data.value;
    },
    components: {
        ViewNav,
        SectionHomeHero,
        HomeButton,
        HomeModal
    },
    methods: {
        onHomeModalClick() {
            this.showModalhome = true
        },
        onModalCloseClick() {
            this.showModalhome = false
        },
    },
    mounted() {
        this.$store.dispatch(LAYOUT_SOCIAL_THEME, 'light');
        this.readyToRender();
    },
    computed: {
        ...mapState({
            home: state => state.app.contents.home,
            metas: state => state.app.contents.home.hero.meta_tags
        }),
    },
}
</script>

<style src="styles/views/Home.styl" lang="stylus"></style>
