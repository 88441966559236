<template>
    <div
        class="CarouselNavigator"
        :class="classModifiers"
    >
        <div
            v-if="progressLabel"
            class="CarouselNavigator-Text"
        >
            <span>{{ currentFormat }}</span> / {{ totalFormat }}
        </div>
        <div class="CarouselNavigator-ButtonsContainer">
            <button
                class="CarouselNavigator-PrevButton"
                @click="$emit('prev')"
            >
                <div class="CarouselNavigator-Background"></div>
                <div class="CarouselNavigator-ArrowLeft">
                    <svg><use xlink:href="#icon-arrow-tail-left"/></svg>
                </div>
            </button>
            <button
                class="CarouselNavigator-NextButton"
                @click="$emit('next')"
            >
                <div class="CarouselNavigator-Background"></div>
                <div class="CarouselNavigator-ArrowRight">
                    <svg><use xlink:href="#icon-arrow-tail-right"/></svg>
                </div>
            </button>
        </div>

    </div>
</template>

<script>
export default {
    name: 'CarouselNavigator',
    props: {
        total: {
            type: Number,
            default: 0,
        },
        current: {
            type: Number,
            default: 0,
        },
        light: {
            type: Boolean,
            default: false,
        },
        progressLabel: {
            type: Boolean,
            default: true,
        }
    },
    methods: {
        padToTwo(number) {
            return (`0${number}`).slice(-2)
        },
    },
    computed: {
        currentFormat() {
            return this.padToTwo(this.current)
        },
        totalFormat() {
            return this.padToTwo(this.total)
        },
        classModifiers() {
            return {
                'CarouselNavigator--light': this.light,
            }
        }
    }
}
</script>

<style src="styles/components/carousel/CarouselNavigator.styl" lang="stylus"></style>
