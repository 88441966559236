<template>
    <wrapper-grid
        class="PreviousWorks"
        :data="data"
    >
    </wrapper-grid>
</template>

<script>
import { mapState } from 'vuex'
import MixHead from '@/app/mixins/MixHead'
import SectionPage from '@/app/mixins/SectionPage'
import WrapperGrid from '@/app/components/WrapperGrid.vue'

export default {
    name: 'PreviousWorks',
    components: {
        WrapperGrid,
    },
    mixins: [
        MixHead,
        SectionPage,
    ],
    computed: {
        ...mapState({
            contents: state => state.app.contents,
        }),
        metas() {
            const { page } = this
            return page.meta_tags
        },
        data() {
            const { contents, page } = this
            return {
                ...contents.prev_works,
                ...page
            }
        }
    },
}
</script>

<style src="styles/views/PreviousWorks.styl" lang="stylus"></style>
