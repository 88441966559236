<template>
    <div
        class="FixedNav"
        :class="modifiers"
        
    >
        <div class="FixedNav-spacer">
            <div
                class="FixedNav-container"
                ref="container"
            >
                <slot></slot>
            </div>
        </div>
    </div>
</template>

<script>
/**
 * Fixed nav container. This container hides automatically if its content
 * overflows. In parent use `v-if` to force this component to hide.
 */
import isMobile from 'ismobilejs'
export default {
    name: 'FixedNav',
    props: {
        bottom: {
            type: Boolean,
            default: false,
        },
        navIsVisible: {
            type: Boolean,
            default: false
        }
    },
    data() {
        return {
            y: 70,
            visible: false,
            isHome: false
        }
    },
    mounted() {
        const {$mq, $route} = this
        this.isHome = ($route.name === 'home') ? true :  false;

        this.navs = document.getElementsByClassName('FixedNav')
        window.addEventListener('resize', this.updateVisibility)
        window.addEventListener('wheel', this.isVisible)
        this.updateVisibility()
        setTimeout(() => {
            this.isVisible()
        }, 200);
    },
    beforeDestroy() {
        window.removeEventListener('resize', this.updateVisibility)
        window.removeEventListener('wheel', this.isVisible)
    },
    methods: {
        updateVisibility() {
            const { $el, $refs } = this
            // const visible = $el.offsetWidth > $refs.container.offsetWidth
            const visible = !isMobile.phone
            // Only emit if visibility actually changed.
            if (visible !== this.visible) {
                this.visible = visible
                this.$emit('visibility-change', {
                    isVisible: visible,
                })
            }
        },
        isVisible() {
            let color = "black";
            if(window.location.pathname == "/") {
                color = "white";
            } else if (window.location.href.indexOf("departamentos-en-venta/") > 0) {
                let _index = Math.floor(this.y/window.innerHeight);
                if (_index > 3) _index = 3;
                color = _index % 2 == 0 ? "white" : "black";
            }
            if (this.navs[1]) this.navs[1].style.color = color;
            if (this.y > 60) {
                this.navs[0].classList.add("white");
            }
            if (window.scrollY > this.y) {
                this.navs[0].classList.add("hidden");
            } else {
                this.navs[0].classList.remove("hidden");
            }
            this.y = window.scrollY;
        },
    },
    computed: {
        modifiers() {
            const { bottom, visible, isHome } = this
            console.log('isHome',isHome);
            if(isHome)
                return {
                    'FixedNav--top': !bottom,
                    'FixedNav--bottom': bottom,
                    'FixedNav--hidden': !visible,
                    'menu_mobile': isHome
                }
            else
                return {
                        'FixedNav--top': !bottom,
                        'FixedNav--bottom': bottom,
                        'FixedNav--hidden': !visible
                }
        },
    }
}
</script>

<style src="styles/components/navs/FixedNav.styl" lang="stylus"></style>
