import { render, staticRenderFns } from "./LayoutSocial.vue?vue&type=template&id=f3195582&"
import script from "./LayoutSocial.vue?vue&type=script&lang=js&"
export * from "./LayoutSocial.vue?vue&type=script&lang=js&"
import style0 from "styles/components/layout/LayoutSocial.styl?vue&type=style&index=0&lang=stylus&"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports