<template>
    <main class="LayoutMain" v-ssr-intercept="onSsrIntercept" :style="styleModifiers">
        <!-- <div class="LayoutMain-GradientTop"></div> -->
        <router-view :key="$route.fullPath"/>
        <!-- <div
            class="LayoutMain-GradientBottom"
            :style="bottomMenuStyle"
        ></div> -->
    </main>
</template>

<script>
import { mapState } from 'vuex'

export default {
    name: 'LayoutMain',
    mixins: [
    ],
    mounted() {
        /*
            This is the safe call to render all views. Any views inside this component
            should be ready to be rendered at this point, if that's not the case then
            you should intercept the SSR inside the view to stall the snapshot.
        */
        this.readyToRender()
    },
    computed: {
        styleModifiers() {
            const visibility = this.isPrerender ? 'hidden' : 'visible'
            return { visibility }
        },
        bottomMenuStyle() {
            const { show_scroll_menu: scrollMenu, $route } = this
            const show = scrollMenu && ['home', 'complex', 'sustainability', 'foundation', 'about'].includes($route.name)
            return { opacity: show ? 1 : 0 }
        },
        viewId() {
            const { $route } = this
            return $route.path
        },
        ...mapState({
            show_scroll_menu: state => state.app.layout.show_scroll_menu,
        })
    }
}
</script>

<style src="styles/components/layout/LayoutMain.styl" lang="stylus"></style>
