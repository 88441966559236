<template>
    <div class="ComplexesGrid">
        <ul class="ComplexesGrid-List item-transition">
            <li
                class="ComplexesGrid-Item"
                v-for="(item, index) in items"
                :key="index"
            >
                <component
                    class="ComplexesGrid-ItemWrapper"
                    :is='item.path ? "router-link" : "div"'
                    :to="item.path"
                >
                    <responsive-image
                        class="ComplexesGrid-Thumb"
                        :mobileSrc="item.thumb.image_mobile"
                        :desktopSrc="item.thumb.image_desktop"
                        background
                    />
                    <div class="ComplexesGrid-SlideContent">
                        <h2 class="ComplexesGrid-Title">{{ item.title }}</h2>
                        <div class="ComplexesGrid-Description" v-html="item.description">
                        </div>
                    </div>
                </component>
            </li>
        </ul>
    </div>
</template>

<script>
import inView from "in-view"
import ResponsiveImage from '@/app/components/ResponsiveImage.vue'

export default {
    name: 'ComplexesGrid',
    components: {
        ResponsiveImage,
    },
    data() {
        return {}
    },
    props: {
        items: {
            type: Array,
            default: () => [],
        },
    },
    mounted() {
        inView('.ComplexesGrid .item-transition').on('enter', (el) => {
            el.classList.add("inview");
        })
    },
    methods: {
        truncate(str) {
            const tStr = str.substr(0, 180)
            if (tStr.length > 135) {
                // return `${tStr}... <span>Ver Más</span>`
                return `${tStr}...`
            }
            return tStr
        }
    },
}
</script>

<style src="styles/components/ComplexesGrid.styl" lang="stylus"></style>
