<template>
    <section class="About">
        <drag-hint hide-on-mobile light/>
        <section-about-services/>
        <section-about-works/>
        <view-nav
            :items="sections"
            :item-active="activeSection"
            light
        />
    </section>
</template>

<script>
import inView from "in-view"
import { LAYOUT_SOCIAL_THEME } from '@/app/store/modules/app'
import ViewNav from '@/app/components/navs/ViewNav.vue'
import MixHomeSections from '@/app/mixins/MixHomeSections'
import MixHead from '@/app/mixins/MixHead'
import SectionAboutServices from '@/app/components/sections/SectionAboutServices.vue'
import SectionAboutWorks from '@/app/components/sections/SectionAboutWorks.vue'
import SectionPage from '@/app/mixins/SectionPage'
import DragHint from '@/app/components/DragHint.vue'

export default {
    name: 'About',
    mixins: [
        MixHead,
        MixHomeSections,
        SectionPage,
    ],
    data() {
        return {
            activeSection: 'about',
        }
    },
    components: {
        ViewNav,
        SectionAboutServices,
        SectionAboutWorks,
        DragHint,
    },
    mounted() {
        inView('.About .item-transition').on('enter', (el) => {
            el.classList.add("inview");
        });
        this.$store.dispatch(LAYOUT_SOCIAL_THEME, 'dark');
        this.readyToRender();
    },
    computed: {
        metas() {
            const { page } = this
            return page.meta_tags
        }
    }
}
</script>

<style src="styles/views/About.styl" lang="stylus"></style>
