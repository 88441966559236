var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('generic-modal',{staticClass:"VideoGalleryModal",on:{"closeClick":function($event){return _vm.$emit('closeClick')}}},[_c('ul',{staticClass:"VideoGalleryModal-CurrentVideo-List"},_vm._l((_vm.items),function(item,index){return _c('li',{key:index,staticClass:"VideoGalleryModal-CurrentVideo-Item"},[(index === _vm.playingIndex)?_c('video-player',{ref:"selectedVideo",refInFor:true,attrs:{"playsinline":true,"options":{
                    controls: false,
                    autoplay: true,
                    sources: [{
                        type: 'video/mp4',
                        src: item.src,
                    }],
                }}}):_vm._e()],1)}),0),_c('div',{staticClass:"VideoGalleryModal-NavWrapper"},[_c('carousel-progress-info',{ref:"progressBar",staticClass:"VideoGalleryModal-Progress",attrs:{"total":_vm.slidesCount,"light":""}}),_c('carousel-navigator',{staticClass:"VideoGalleryModal-Navigator",attrs:{"progressLabel":false},on:{"next":_vm.onNextClick,"prev":_vm.onPrevClick}})],1),_c('carousel',{ref:"carouselPreview",staticClass:"VideoGalleryModal-CarouselPreview",attrs:{"options":_vm.carouselPreviewOptions},on:{"initialized":_vm.updateNavigator,"change":_vm.updateNavigator}},_vm._l((_vm.items),function(item,index){return _c('carousel-slide',{key:index,staticClass:"VideoGalleryModal-PreviewSlide",nativeOn:{"click":function($event){return _vm.onPreviewClick(index)}}},[_c('video-player',{ref:"previewVideos",refInFor:true,attrs:{"playsinline":true,"options":{
                    fluid: true,
                    controls: false,
                    autoplay: false,
                    sources: [{
                        type: 'video/mp4',
                        src: item.src,
                    }],

                }}})],1)}),1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }