import jsonata from 'jsonata'

/*
  Transforms https://dev-begrand.fooprojects.com/cms/api/sections
  Live Editor:
  Docs: https://github.com/jsonata-js/jsonata
*/
const transform = jsonata(`
$.{
  "permalink": perma_link,
  "category_name": "Legales",
  "meta_tags": {
    "title": meta_title,
    "description": meta_description
  },
  "content": {
    "title": name,
    "body": information,
    "attachment": file
  },
  "cover": {
    "image_desktop": cover_image_desktop,
    "image_mobile": cover_image_mobile
  }
}`);

export default transform;
