<template>
    <transition
        @leave="onTransitionLeave"
    >
        <div class="Preloader"></div>
    </transition>
</template>

<script>
import { mapActions } from "vuex";
import { ASSET_LOADING, DATA } from "@/app/store/modules/app"
import dataUrl from 'assets/data/data.json'
import preloader from "@/app/utils/preloader"
import ApiLoader from "foo/net/ApiLoader"

export default {
    name: "Preloader",
    data(){
        return {
            progress: 0,
            loading: true,
        };
    },
    async mounted() {
        await Promise.all([
            ApiLoader.load(),
            this.setLoader(),
            this.playIntro(),
        ])
        this.playOutro()
        this.loadingComplete()
    },
    methods: {
        setLoader(){
            return new Promise((resolve) => {
                this.loader = preloader
                this.loader.on("progress", this.onProgress)
                this.loader.on('complete', () => this.onComplete(resolve))
                this.loader.load();
            });
        },
        playIntro() {
            const tl = new TimelineMax({ delay: 0 })
            return new Promise(resolve => tl.eventCallback('onComplete', resolve))
        },
        async onTransitionLeave(el, done) {
            await this.playOutro()
            done()
        },
        playOutro() {
            const tl = new TimelineMax({ delay: 0 })
            return new Promise(resolve => tl.eventCallback('onComplete', resolve))
        },
        onProgress(val) {
            this.progress = val
        },
        onComplete(done) {
            this.progress = 1
            const data = preloader.get(dataUrl)
            this.$store.commit(DATA, data)
            done();
        },
        loadingComplete() {
            this.loading = false
            this.loadingState(false)
        },
        ...mapActions({
            loadingState: ASSET_LOADING,
        })
    },
};
</script>

<style src="styles/components/base/Preloader.styl" lang="stylus"></style>