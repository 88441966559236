<template>
    <div
        class="SectionSustainabilityBlock item-transition"
        :class="classModifiers"
        v-viewport-size:debounce.100="viewport"
    >
        <div class="SectionSustainabilityBlock-Content">
            <slot name="btn"></slot>
            <slot></slot>
            <h1>{{ data.subtitle }}</h1>
            <h2
                class="SectionSustainabilityBlock-Subtitle item-transition"
                v-html="data.subtitle"
            ></h2>
            <div
                class="SectionSustainabilityBlock-Description item-transition"
                v-html="data.description"
            ></div>
        </div>
        <div class="SectionSustainabilityBlock-Images item-transition">
            <div class="SectionSustainabilityBlock-ImagesWrapper">
                <responsive-image
                    class="SectionSustainabilityBlock-BackImage item-transition"
                    :mobileSrc="data.image_top"
                    :desktopSrc="data.image_top"
                    background
                >
                    <responsive-image
                        class="SectionSustainabilityBlock-FrontImage"
                        :mobileSrc="data.image_bottom"
                        :desktopSrc="data.image_bottom"
                        background
                    />
                </responsive-image>
            </div>
        </div>
    </div>
</template>

<script>
import ResponsiveImage from "@/app/components/ResponsiveImage.vue";
import SectionUtils from "@/app/mixins/SectionUtils";

export default {
    name: "SectionSustainabilityBlock",
    components: {
        ResponsiveImage
    },
    mixins: [SectionUtils],
    data() {
        return {
            viewport: {
                height: 100,
                predicate: w => w > 1024,
                onResize: () => {
                    this.onResize();
                }
            }
        };
    },
    props: {
        align: {
            type: String,
            default: "right"
        },
        data: {
            type: Object,
            default: () => ({})
        }
    },
    computed: {
        classModifiers() {
            return {
                [`SectionSustainabilityBlock--${this.align}`]: true
            };
        }
    }
};
</script>

<style
    src="styles/components/sections/SectionSustainabilityBlock.styl"
    lang="stylus"
></style>
