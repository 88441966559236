/**
 * Helper class for working with Twitter API
 *
 *      TODO: IMPLEMENT ALL
 * @class Twitter
 * @namespace net.api
 */

import AbstractApi from "./AbstractApi";

export default class Twitter extends AbstractApi {
    static sdkName = "Twitter";
}
