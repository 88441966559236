<template>
    <div
        class="SectionAboutServices"
        v-viewport-size:debounce.100='viewport'
    >
        <div class="SectionAboutServices-Info item-transition">
            <h1>{{ page.content.title }}</h1>
            <h2>{{ page.content.subtitle }}</h2>
            <div v-html="page.content.description"></div>
        </div>
        <div class="SectionAboutServices-Showcase">
            <div class="SectionAboutServices-Controls item-transition">
                <h2 class="SectionAboutServices-GalleryTitle">Galería</h2>
                <carousel-progress-info
                    class="SectionAboutServices-Progress"
                    :total="slidesCount"
                    :current="slideIndex"
                    :progress="slidesProgress"
                    light
                />
                <carousel-navigator
                    class="SectionAboutServices-Navigator"
                    :progressLabel="false"
                    @next="onNextClick"
                    @prev="onPrevClick"
                    :total="slidesCount"
                    :current="slideIndex"
                />
            </div>
            <carousel
                class="SectionAboutServices-Carousel"
                ref="carousel"
                :options="carouselOptions"
                @change="updateNavigator"
                @initialized="updateNavigator"
            >
                <carousel-slide
                    v-for="(slide, index) in page.slides"
                    :key="index"
                >
                    <responsive-image
                        :mobileSrc="slide.background.image_mobile"
                        :desktopSrc="slide.background.image_desktop"
                        background
                    />
                </carousel-slide>
            </carousel>
            <ul class="SectionAboutServices-SectionList item-transition">
                <li
                    class="SectionAboutServices-SectionItem"
                    v-for="(item, index) in section_links"
                    :key="index"
                >
                    <flat-button
                        class="SectionAboutServices-SectionLink"
                        :el="item.path ? 'router-link' : 'a'"
                        :to="item.path"
                        :href="item.href"
                        :target="item.blank"
                        outline
                    >{{ item.text }}</flat-button>
                </li>
            </ul>
        </div>
    </div>
</template>

<script>
import FlatButton from '@/app/components/buttons/FlatButton.vue'
import CarouselProgressInfo from '@/app/components/carousel/CarouselProgressInfo.vue'
import CarouselNavigator from '@/app/components/carousel/CarouselNavigator.vue'
import Carousel from '@/app/components/carousel/Carousel.vue'
import CarouselSlide from '@/app/components/carousel/CarouselSlide.vue'
import ResponsiveImage from '@/app/components/ResponsiveImage.vue'
import SectionPage from '@/app/mixins/SectionPage'

export default {
    name: 'SectionAboutServices',
    components: {
        ResponsiveImage,
        FlatButton,
        CarouselProgressInfo,
        CarouselNavigator,
        CarouselSlide,
        Carousel,
    },
    mixins: [
        SectionPage,
    ],
    data() {
        return {
            slidesCount: 0,
            slideIndex: 0,
            slidesProgress: 0,
            carouselOptions: {
                loop: true,
            },
            section_links: [
                {
                    text: "Responsabilidad Corporativa",
                    path: "/responsabilidad-corporativa"
                },
                {
                    text: "Inversionistas",
                    path: "/inversionistas"
                },
                {
                    text: "Gobierno Corporativo",
                    path: "/gobierno-corporativo"
                },
                {
                    text: "Bolsa de Trabajo",
                    path: "/bolsa-de-trabajo"
                },
            ],
            viewport: {
                height: 100,
                predicate: w => w > 1024,
            },
        }
    },
    methods: {
        updateNavigator() {
            const { carousel } = this.$refs
            this.slideIndex = carousel.index + 1
            this.slidesCount = carousel.count
            this.slidesProgress = carousel.progress
        },
        onNextClick() {
            const { carousel } = this.$refs
            carousel.nextSlide()
        },
        onPrevClick() {
            const { carousel } = this.$refs
            carousel.prevSlide()
        },
    },
}
</script>

<style src="styles/components/sections/SectionAboutServices.styl" lang="stylus"></style>