import 'sanitize.css/sanitize.css'
import './main.styl'

import 'objectFitPolyfill'
import 'url-polyfill'
import '@/app/utils/polyfills'

import 'gsap'
import './app'

require('gsap/ScrollToPlugin')
