<template>
    <section
        class="SectionApartments"
        :id="data.id"
        v-viewport-size:debounce.100='SectionUtils.viewport'
    >
        <div class="SectionApartments-HeaderMobile">
            <h1 class="item-transition">Conoce nuestros</h1>
            <h2 class="item-transition">{{ data.content.subtitle }}</h2>
            <div class="item-transition" v-html='data.content.description'></div>
        </div>
        <div class="SectionApartments-Types item-transition">
            <carousel
                ref="carousel"
                :options='carouselOptions'
                @change="onSlideChange"
                @initialized="updateNavigator"
            >
                <carousel-slide
                    class="SectionApartments-Slide"
                    v-for="(item, index) in data.slides"
                    :key="index">
                    <responsive-image
                        class="SectionApartments-SlideBackground"
                        :mobileSrc="item.background.image_mobile"
                        :desktopSrc="item.background.image_desktop"
                        background
                    />
                </carousel-slide>
            </carousel>
            <div class="SectionApartments-NavWrapper item-transition">
                <carousel-progress-info
                    class="SectionApartments-Progress"
                    :total="slidesCount"
                    :current="slideIndex"
                    :progress="slideProgress"
                    light
                />
                <carousel-navigator
                    class="SectionApartments-Navigator"
                    :progressLabel="false"
                    @next="onNextClick"
                    @prev="onPrevClick"
                    :total="slidesCount"
                    :current="slideIndex"
                    :light="$mq === 'mobile'"
                />
            </div>
        </div>
        <div class="SectionApartments-Content">
            <div class="SectionApartments-HeaderDesktop">
                <h1 class="item-transition">Conoce nuestros</h1>
                <h2 class="item-transition">{{ data.content.subtitle }}</h2>
                <div
                    class="SectionApartments-Description item-transition"
                    v-html='data.content.description'>
                </div>
            </div>
            <template v-if="data.tours.length > 0">
                <div class="SectionApartments-TopButtons">
                    <flat-button
                        class="SectionApartments-ThreeSixtyButton item-transition"
                        @click.native="onThreeSixtyClick"
                        v-show="data.tours.length > 0"
                        iconId="icon-world"
                        iconLeft
                    >Vista 360°</flat-button>
                    <flat-button
                        class="SectionApartments-LayoutsButton item-transition"
                        el="router-link"
                        :to="layoutsRoute"
                        iconId="icon-modal"
                        iconLeft
                        v-show="data.layouts.length > 0"
                    >Planos</flat-button>
                </div>
                <div class="SectionApartments-TopButtons">
                    <flat-button
                        class="SectionApartments-VideosButton item-transition"
                        v-show="data.videos.length > 0"
                        @click.native="onVideoGalleryClick"
                        iconId="icon-play"
                        iconLeft
                    >Videos</flat-button>
                    <flat-button
                        class="SectionApartments-VideosButton item-transition"
                        @click.native="onMagazineClick"
                        iconId="Brochure"
                        iconLeft
                    >Brochure</flat-button>
                </div>
            </template>
            <template v-else>
                <div class="SectionApartments-TopButtons">
                    <flat-button
                        class="SectionApartments-VideosButton item-transition"
                        @click.native="onMagazineClick"
                        iconId="Brochure"
                        iconLeft
                    >Brochure</flat-button>
                    <flat-button
                        class="SectionApartments-LayoutsButton item-transition"
                        el="router-link"
                        :to="layoutsRoute"
                        iconId="icon-modal"
                        iconLeft
                        v-show="data.layouts.length > 0"
                    >Planos</flat-button>
                </div>
                <div class="SectionApartments-TopButtons">
                    <flat-button
                        class="SectionApartments-VideosButton item-transition"
                        v-show="data.videos.length > 0"
                        @click.native="onVideoGalleryClick"
                        iconId="icon-play"
                        iconLeft
                    >Videos</flat-button>                    
                </div>
            </template>
            
            <carousel
                class="SectionApartments-CarouselPreview"
                ref="carouselPreview"
                @slideClick="onPreviewClick"
                :options="carouselPreviewOptions">
                <carousel-slide
                    class="SectionApartments-PreviewSlide"
                    v-for="(item, index) in data.slides"
                    :key="index">
                    <responsive-image
                        class="SectionApartments-SlideBackground"
                        :mobileSrc="item.background.image_mobile"
                        :desktopSrc="item.background.image_desktop"
                        background/>
                </carousel-slide>
            </carousel>
        </div>
        <three-sixty-modal
            v-if="showThreeSixty"
            @closeClick="onModalCloseClick"
            :items='data.tours'
        />
        <video-gallery-modal
            v-if="showVideoGallery"
            @closeClick="onModalCloseClick"
            :items='data.videos'
        />
    </section>
</template>

<script>
import inView from "in-view"
import FlatButton from '@/app/components/buttons/FlatButton.vue'
import Carousel from '@/app/components/carousel/Carousel.vue'
import CarouselSlide from '@/app/components/carousel/CarouselSlide.vue'
import CarouselNavigator from '@/app/components/carousel/CarouselNavigator.vue'
import CarouselProgressInfo from '@/app/components/carousel/CarouselProgressInfo.vue'
import ResponsiveImage from '@/app/components/ResponsiveImage.vue'
import ThreeSixtyModal from '@/app/components/modals/ThreeSixtyModal.vue'
import VideoGalleryModal from '@/app/components/modals/VideoGalleryModal.vue'
import SectionUtils from '@/app/mixins/SectionUtils'

export default {
    name: 'SectionApartments',
    data() {
        return {
            slidesCount: 0,
            slideIndex: 0,
            slideProgress: 0,
            showThreeSixty: false,
            showVideoGallery: false,
            carouselOptions: {
                loop: true,
            },
            carouselPreviewOptions: {
                loop: true,
                slidesPerView: 2,
                spaceBetween: 20,
                initialSlide: 1,
                allowTouchMove: false,
                breakpoints: {
                    994: {
                        slidesPerView: 3,
                        spaceBetween: 10,
                    },
                },
            }
        }
    },
    mounted() {
        inView('.SectionApartments .item-transition').on('enter', (el) => {
            el.classList.add("inview");
        });
    },
    mixins: [
        SectionUtils,
    ],
    components: {
        Carousel,
        ResponsiveImage,
        CarouselNavigator,
        CarouselProgressInfo,
        CarouselSlide,
        FlatButton,
        ThreeSixtyModal,
        VideoGalleryModal,
    },
    props: {
        data: {
            type: Object,
            default: () => ({
                id: '',
                menu_name: '',
                threeSixty: [],
                layouts: [],
                content: {
                    title: '',
                    description: ''
                },
            })
        },
    },
    methods: {
        onThreeSixtyClick() {
            this.showThreeSixty = true
            // TODO: commit to block scroll
        },
        onMagazineClick() {
            const vect = window.location.pathname.split("/");
            const complex = (vect[vect.length - 1]);
            const api = `https://${location.host}/cms/revista/complejo/${complex}`;
            location.href = api;
        },
        onVideoGalleryClick() {
            this.showVideoGallery = true
        },
        onModalCloseClick() {
            this.showThreeSixty = false
            this.showVideoGallery = false
        },
        getRouteToApartmentType(apartmentType) {
            const { $route } = this
            return {
                name: "apartment-type",
                params: {
                    complex: $route.params.complex,
                    apartment: apartmentType.id,
                }
            }
        },
        onNextClick() {
            const { carousel } = this.$refs
            carousel.nextSlide()
        },
        onPrevClick() {
            const { carousel } = this.$refs
            carousel.prevSlide()
        },
        updateNavigator() {
            const { carousel } = this.$refs
            this.slideIndex = carousel.index + 1
            this.slidesCount = carousel.count
            this.slideProgress = carousel.progress
        },
        onSlideChange(prevIndex, currIndex) {
            const { carouselPreview } = this.$refs
            carouselPreview.slideTo(currIndex + 1)
            this.updateNavigator()
        },
        onPreviewClick(index) {
            const { carousel } = this.$refs
            carousel.slideTo(index)
        }
    },
    computed: {
        layoutsRoute() {
            const { $route, data } = this
            const obj = {
                name: 'apartment-type',
                params: {
                    complex: $route.params.complex,
                    apartment: data.layouts[0] ? data.layouts[0].id : 1,
                    id: data.layouts[0] ? data.layouts[0].idapa : 1
                },
            }
            return obj;
        }
    }
}
</script>

<style src="styles/components/sections/SectionApartments.styl" lang="stylus"></style>
