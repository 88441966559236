<template>
    <section
        class="ApartmentType"
        v-viewport-size:debounce.100='SectionUtils.viewport'
    >
        <responsive-image
            class="ApartmentType-Background"
            :mobileSrc="apartment.background.image_mobile"
            :desktopSrc="apartment.background.image_desktop"
            background
        >
            <h1 class="ApartmentType-Title">Departamento <strong>{{ apartment.content.title }}</strong></h1>
            <carousel-navigator
                v-if="showLayoutsNav"
                :progressLabel="false"
                class="ApartmentType-NavigatorMobile"
                @next="onNextClick"
                @prev="onPrevClick"
                :total="slidesCount"
                :current="slideIndex"
                light
            />
        </responsive-image>
        <div class="ApartmentType-Content">
            <div class="ApartmentType-SwiperContainer">
                <carousel
                    class="ApartmentType-Carousel"
                    ref="carousel"
                    :options="carouselOptions"
                    @change="onSlideChange"
                    @initialized="updateNavigator"
                >
                    <carousel-slide
                        v-for="(layout, index) in apartment.layouts"
                        :key="index"
                    >
                        <responsive-image
                            class="ApartmentType-Image"
                            :mobileSrc="layout.image_mobile"
                            :desktopSrc="layout.image_desktop"
                            background
                            contain
                        />
                    </carousel-slide>
                </carousel>
                <div
                    v-if="showPagination"
                    class="ApartmentType-SwiperPagination"
                    slot="pagination"
                >
                    Piso:
                    <span
                        v-for="(layout, index) in apartment.layouts"
                        :key="index"
                        :class="getActiveClassSwiper(index)"
                        @click="onLayoutClick(index)"
                    >{{ index + 1 }}</span>
                </div>
            </div>
            <div class="ApartmentType-FeaturesWrapper">
                <carousel-navigator
                    v-if="showLayoutsNav"
                    :progressLabel="false"
                    class="ApartmentType-Navigator"
                    @next="onNextClick"
                    @prev="onPrevClick"
                    light
                />
                <div class="ApartmentType-Features">
                    <h1 class="ApartmentType-Title">Departamento <strong>{{ apartment.content.title }}</strong></h1>
                    <ul class="ApartmentType-List">
                        <li
                            class="ApartmentType-Item"
                            v-for="(item, index) in apartment.features"
                            :key="index"
                        >
                            <svg class="ApartmentType-ItemIcon">
                                <use :xlink:href="`#icon-${item.icon}`"/>
                            </svg>
                            <span class="ApartmentType-ItemText" v-html="item.text">
                            </span>
                        </li>
                        <li
                            v-if="apartment.content.price_enabled"
                            class="ApartmentType-Price">
                            <b>Desde: {{ apartment.content.price_text }}</b>
                        </li>
                    </ul>
                </div>
            </div>
            <div class="ApartmentType-Nav">
                <flat-button
                    class="ApartmentType-NavButton"
                    iconId="icon-arrow-left"
                    iconLeft
                    outline
                    :to="backRoute"
                    el="router-link"
                >
                    Volver
                </flat-button>
            </div>
        </div>
    </section>
</template>

<script>
import { LAYOUT_SOCIAL_THEME } from '@/app/store/modules/app'
import SectionUtils from '@/app/mixins/SectionUtils'
import ComplexUtils from '@/app/mixins/ComplexUtils'
import FlatButton from '@/app/components/buttons/FlatButton.vue'
import ResponsiveImage from '@/app/components/ResponsiveImage.vue'
import CarouselSlide from '@/app/components/carousel/CarouselSlide.vue'
import Carousel from '@/app/components/carousel/Carousel.vue'
import CarouselNavigator from '@/app/components/carousel/CarouselNavigator.vue'

export default {
    name: 'ApartmentType',
    components: {
        CarouselNavigator,
        ResponsiveImage,
        CarouselSlide,
        FlatButton,
        Carousel,
    },
    mixins: [
        SectionUtils,
        ComplexUtils,
    ],
    data() {
        return {
            carouselOptions: { loop: true },
            slideIndex: 0,
            slidesCount: 0,
        }
    },
    methods: {
        getMod(current, limit) {
            return ((current % limit) + limit) % limit;
        },
        navigateToApartmentLayout(step) {
            const { complex, $router, $route, apartment } = this
            const current = complex.apartment_types.indexOf(apartment)
            const nextIndex = this.getMod(current + step, complex.apartment_types.length)
            const nextApartment = complex.apartment_types[nextIndex]
            $router.push({
                name: 'apartment-type',
                params: {
                    ...$route.params,
                    apartment: nextApartment.id,
                    id: nextApartment.idapa
                }
            })

        },
        onNextClick() {
            this.navigateToApartmentLayout(1)
        },
        onPrevClick() {
            this.navigateToApartmentLayout(-1)
        },
        onLayoutClick(layout) {
            const { carousel } = this.$refs
            carousel.slideTo(layout)
        },
        getActiveClassSwiper(index) {
            const { slideIndex } = this
            return {
                'ApartmentType-PaginationActive': slideIndex === index
            }
        },
        updateNavigator() {
            const { carousel } = this.$refs
            this.slideIndex = carousel.index
            this.slidesCount = carousel.count
        },
        onSlideChange() {
            this.updateNavigator()
        },
    },
    mounted() {
        this.$store.dispatch(LAYOUT_SOCIAL_THEME, 'light');
    },
    computed: {
        apartment() {
            const { $route, complex } = this
            const urlType = $route.params.id
            let cmp = complex.apartment_types.find(apt => apt.idapa === urlType)
            if (!cmp){
                cmp = complex.apartment_types.find(apt => apt.id === $route.params.apartment)
            }
            console.log('COMPLEX')
            console.log(cmp)
            return cmp
        },
        showPagination() {
            const { apartment } = this
            return apartment.layouts && apartment.layouts.length > 1
        },
        showLayoutsNav() {
            const { complex } = this
            return complex.apartment_types.length > 1
        },
        backRoute() {
            const { $route } = this
            return { name: 'complex', params: { complex: $route.params.complex }, hash: '#tipos' }
        }
    }
}
</script>

<style src="styles/views/ApartmentType.styl" lang="stylus"></style>
