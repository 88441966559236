<template>
    <div
        class="LayoutMenuBtn"
        :class="modifiers"
        @click="$emit('click')"
    ></div>
</template>

<script>
export default {
    props: {
        light: {
            type: Boolean,
            default: false,
        },
    },
    name: 'LayoutMenuBtn',
    computed: {
        modifiers() {
            return {
                'LayoutMenuBtn--light': this.light
            }
        },
    },
}
</script>

<style src="styles/components/layout/LayoutMenuBtn.styl" lang="stylus"></style>
