<template>
    <swiper-slide
        class="CarouselSlide"
        :class="classModifiers"
    >
        <slot></slot>
    </swiper-slide>
</template>

<script>
import { swiperSlide } from 'vue-awesome-swiper'

export default {
    name: 'CarouselSlide',
    components: {
        swiperSlide,
    },
    data() {
        return {}
    },
    props: {
        darken: {
            type: Boolean,
            default: false,
        },
    },
    computed: {
        classModifiers() {
            return {
                'CarouselSlide--darken': this.darken,
            }
        },
    },
}
</script>

<style src="styles/components/carousel/CarouselSlide.styl" lang="stylus"></style>
