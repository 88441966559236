export default {
    head: {
        title() {
            return { inner: `${this.metas.title}`, separator: '|', complement: 'Be Grand' };
        },
        meta() {
            return [
                // Replace default meta description at (index.hbs)
                { name: 'description', content: this.metas.description, id: 'desc' }
            ];
        }
    },
    computed: {
        // @override metas computed inside section
        metas() {
            return {
                metas: {
                    title: '',
                    description: '',
                }
            };
        }
    }
};