export default {
    data() {
        return {
            SectionUtils: {
                viewport: {
                    height: 100,
                    predicate: w => w > 860,
                    onResize: () => {
                        this.onResize()
                    },
                },
            }
        }
    },
    methods: {
        onResize() {}
    }
}
