<template>
    <section
        class="ComplexSchedule"
        v-viewport-size:debounce.100='viewport'
    >
        <responsive-image
            class="ComplexSchedule-Background"
            :mobileSrc="background.image_mobile"
            :desktopSrc="background.image_desktop"
            background
        >
        </responsive-image>
        <schedule-widget
            :items="complexesItems"
            class="ComplexSchedule-Widget"
            ref="widget"
        ></schedule-widget>
    </section>
</template>

<script>
import { mapState } from 'vuex'
import ResponsiveImage from '@/app/components/ResponsiveImage.vue'
import ScheduleWidget from '@/app/components/ScheduleWidget.vue'
import ComplexUtils from '@/app/mixins/ComplexUtils'
import MixHead from '@/app/mixins/MixHead'

export default {
    name: 'ComplexSchedule',
    data() {
        return {
            viewport: {
                height: 100,
                predicate: (w, h) => w > 850 && h > 800
            },
        }
    },
    components: {
        ResponsiveImage,
        ScheduleWidget,
    },
    mixins: [
        ComplexUtils,
        MixHead,
    ],
    computed: {
        ...mapState({
            complexesItems: state => state.app.data.complexes.items,
        }),
        background() {
            return this.complex.schedule.background
        },
        metas() {
            const { complex } = this
            return complex.meta_tags
        }
    }

}
</script>

<style src="styles/views/ComplexSchedule.styl" lang="stylus"></style>
