import jsonata from 'jsonata'

/*
  Transforms https://dev-begrand.fooprojects.com/cms/api/old_complexes_all
  Docs: https://github.com/jsonata-js/jsonata
*/
const transform = jsonata(`\${
  "content": {
    "title": "Complejos Anteriores",
    "subtitle": "Oficinas"
  },
  "tags": $$.name,
  "items": $.(
    $tag := $.name;
    $.complexes.{
      "tag": $tag,
      "title": title,
      "description": description,
      "thumb": {
        "image_desktop": image_desktop,
        "image_mobile": image_mobile
      }
    }
  )
}`);

export default transform;
