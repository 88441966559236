<template>
    <fixed-nav
        class="ViewNav"
        :class="modifiers"
        v-show="menuVisible"
        bottom
    >
        <div class="ViewNav-container" ref="container">
            <progress-bar
                class="ViewNav-progress"
                :value="progress"
                :light="light"
            />
            <ul class="ViewNav-list">
                <li
                    class="ViewNav-item"
                    ref="item"
                    v-for="item in items"
                    :key="item.id"
                    :data-target="item.id"
                    :class="itemModifiers(item.id)"
                    @click="$emit('item-click', item.id)"
                >
                    <template v-if="item.router_to">
                        <router-link :to='item.router_to'>
                            {{ item.menu_name }}
                        </router-link>
                    </template>
                    <template v-else>
                        {{ item.menu_name }}
                    </template>
                </li>
            </ul>
        </div>
    </fixed-nav>
</template>

<script>
import { mapState } from 'vuex'
import FixedNav from '@/app/components/navs/FixedNav.vue'
import ProgressBar from '@/app/components/ProgressBar.vue'

export default {
    name: 'ViewNav',
    props: {
        items: {
            type: Array,
            required: true,
        },
        itemActive: {
            type: String,
            default: '',
        },
        light: {
            type: Boolean,
            default: false,
        },
    },
    components: {
        FixedNav,
        ProgressBar,
    },
    data() {
        return {
            progress: 0,
            menuVisible: true,
        }
    },
    mounted() {
        this.setProgress()
        window.addEventListener('resize', this.setProgress)
    },
    beforeDestroy() {
        window.removeEventListener('resize', this.setProgress)
    },
    methods: {
        getActiveItem() {
            const { $refs, itemActive } = this
            return $refs.item
                .find(item => item.dataset.target === itemActive)
        },
        setProgress() {
            const item = this.getActiveItem()
            if (item) {
                const { $refs } = this
                const { left, width } = $refs.container.getBoundingClientRect()
                const itemRect = item.getBoundingClientRect()
                this.progress = (itemRect.left + itemRect.width - left) / width
            } else {
                this.progress = 0
            }
        },
        itemModifiers(id) {
            const { itemActive } = this
            return {
                'ViewNav-item--active': id === itemActive,
            }
        },
    },
    computed: {
        modifiers() {
            const { light } = this
            return {
                'ViewNav--light': light,
            }
        },
        ...mapState({
            show_scroll_menu: state => state.app.layout.show_scroll_menu,
        })
    },
    watch: {
        itemActive() {
            this.setProgress()
        },
        show_scroll_menu(value) {
            this.menuVisible = value
        }
    },
}
</script>

<style src="styles/components/navs/ViewNav.styl" lang="stylus"></style>
